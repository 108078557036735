import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { combineLatest, Subscription } from 'rxjs';
import { filter, map, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { Title, Meta } from '@angular/platform-browser';

import {
    Collection,
    GetProductAllDetailQuery,
    GetProductAllDetailQueryVariables,
    StoreSite,
    HeroSectionData
} from '../../../common/generated-types';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { DataService } from '../../../core/providers/data/data.service';
import { StateService } from '../../../core/providers/state/state.service';

import { GET_PRODUCT_ALL_DETAIL } from '../../../common/graphql/documents.graphql';
import { ActiveService } from '../../../core/providers/active/active.service';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../../core/providers/analytics/combined-analytics.service';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CollectionGroupData, ColorConfig, FeaturedCollectionData } from '../../../common/interfaces';
import { stripHtml } from '../../../common/utils/strip-html';
import { replaceHostname } from '../../../common/utils/url-handlers';
import { CanonicalService } from '../../../core/providers/canonical/canonical.service';

type Variant = NonNullable<GetProductAllDetailQuery['product']>['variants'][number];

declare let document: Document & typeof globalThis;

@Component({
    selector: 'vsf-product-showcase',
    templateUrl: './product-showcase.component.html',
    styleUrls: ['./product-showcase.component.scss'],
})
export class ProductShowcaseComponent implements OnInit, OnDestroy {

    product: GetProductAllDetailQuery['product'];
    qtyInCart: { [id: string]: number; } = {};
    selectedVariant: Variant;
    qty = 1;
    breadcrumbs: Collection['breadcrumbs'] | null = null;
    inFlight = false;
    inStock = true;
    @ViewChild('addedToCartTemplate', {static: true})
    private sub: Subscription;
    private orderSub: Subscription;
    selectedVariantMSRP: number | null = null;
    discountPercentage: number | null = null;
    isMobile: boolean | null = null;
    baseUrl = '';
    shopHost: string;
    currentCanonicalURL: string | null = null;
    heroSection: HeroSectionData | null = null;
    colorConfig: ColorConfig | null = null;
    collectionListForShopByGroupList: CollectionGroupData[] = [];
    featuredCollectionDataList: FeaturedCollectionData[] = [];
    crossSellingFeaturedCollection: FeaturedCollectionData | null;
    storeSite: StoreSite | null = null;

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private dataService: DataService,
                private stateService: StateService,
                private activeService: ActiveService,
                private canonicalService: CanonicalService,
                private route: ActivatedRoute,
                private router: Router,
                private titleService: Title,
                private metaService: Meta,
                private changeDetector: ChangeDetectorRef,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger) {
    }

    async ngOnInit() {
        const isMobile$ = this.stateService.select(state => state.isMobile);
        const storeInfo$ = this.stateService.select(state => state.storeInfo);
        const storePath$ = this.stateService.select(state => state.storePath);
        const shopHost$ = this.stateService.select(state => state.shopHost);
        await combineLatest([isMobile$, storeInfo$, storePath$, shopHost$]).pipe(take(1)).subscribe(([isMobile, storeInfo, storePath, shopHost]) => {
            this.isMobile = isMobile;
            this.storeSite = storeInfo;
            this.baseUrl = storePath;
            this.shopHost = shopHost;
        });
        const productSlug$ = this.route.paramMap.pipe(
            map(paramMap => paramMap.get('slug')),
            filter(notNullOrUndefined),
        );
        const variantId$ = this.route.queryParamMap.pipe(
            map(params => params.get('variantid')),
        );

        this.sub = combineLatest([productSlug$, variantId$]).pipe(
            switchMap(([slug, variantId]) => {
                return this.dataService.query<GetProductAllDetailQuery, GetProductAllDetailQueryVariables>(GET_PRODUCT_ALL_DETAIL, {
                    slug,
                });
            }),
            map(data => data.product),
            withLatestFrom(variantId$),
        ).subscribe(([product, variantId]) => {
            if (!product) {
                this.router.navigate([`${this.baseUrl}/`]);
                return;
            }
            this.product = product;
            if (variantId) {
                const variant = product.variants.find(v => v.id === variantId);
                if (variant) {
                    this.selectedVariant = variant;
                } else {
                    this.selectedVariant = product.variants[0];
                }
            } else {
                this.selectedVariant = product.variants[0];
            }
            const canonicalURL = `${this.baseUrl}/dp/${product.slug}`;
            this.canonicalService.setCanonicalURL(`${this.shopHost}${canonicalURL}`);
            this.updateProductMetadata(product);
            this.currentCanonicalURL = canonicalURL;
            try {
                this.selectItemEvent(this.product);
            } catch (e) {
                this.logger.error('Error in select ItemEvent', e);
            }

            this.logger.debug(`[productDetails]isMobile:${this.isMobile} product:${JSON.stringify(this.product)}`);
            this.heroSection = this.product?.heroSection as HeroSectionData || null;
            this.colorConfig = safeJSONParse<ColorConfig>(this.storeSite?.colorConfig, this.logger);
            this.collectionListForShopByGroupList = this.storeSite?.collectionGroupDataList as CollectionGroupData[] || [];

            const featuredCollections = this.storeSite?.featuredCollectionDataList as FeaturedCollectionData[] || null;
            this.crossSellingFeaturedCollection = (featuredCollections?.length > 0) ? featuredCollections[0] : null;
            if(!variantId) {
                this.selectedVariant = product.variants[0];
            }
            this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
            this.updateMSRP();
            this.router.navigate([], {
                relativeTo: this.route,
                queryParams: { variantid: this.selectedVariant.id },
                queryParamsHandling: 'merge',
            });
            this.changeDetector.detectChanges();
        });

        this.orderSub = this.activeService.activeOrder$.subscribe(order => {
            this.qtyInCart = {};
            for (const line of order?.lines ?? []) {
                this.qtyInCart[line.productVariant.id] = line.quantity;
            }
        });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
        if (this.orderSub) {
            this.orderSub.unsubscribe();
        }
        this.removeMetadata();
        if (this.currentCanonicalURL) {
            this.canonicalService.removeCanonicalURL();
            this.currentCanonicalURL = null;
        }
    }

    private updateProductMetadata(product: any): void {
        if (!product) return;
        const heroSection = product?.heroSection;
        // Set the page title
        this.titleService.setTitle(`${product.name}`);
        // Set meta description
        const description = product.customFields?.OGDescription ? product.customFields?.OGDescription :
          (product.customFields?.ProductSubtitle ? product.customFields?.ProductSubtitle :
            (stripHtml(product.description, this.platformId)));
        this.metaService.updateTag({ name: 'description', content: description });
        // Set Open Graph meta tags
        this.metaService.updateTag({ property: 'og:title', content: product.name });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:type', content: 'product' });
        this.metaService.updateTag({ property: 'og:url', content: `${this.shopHost}${this.baseUrl}/dp/${product.slug}` });
        // Set Twitter Card meta tags
        this.metaService.updateTag({ name: 'twitter:title', content: product.name });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        // Set image meta tags
        const fp = product.featuredAsset?.focalPoint ? `&fpx=${product.featuredAsset.focalPoint.x}&fpy=${product.featuredAsset.focalPoint.y}` : '';
        const imageUrl = product.customFields.OGImage ? (`${product.customFields.OGImage.preview}?preset='full'&format=webp&cache=true`) :
          ((heroSection?.medias && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') ?
            (`${heroSection?.medias[0].preview}?preset='full'&format=webp&cache=true`) :
            (product.featuredAsset ? (`${product.featuredAsset?.preview}?w=600&h=315${fp}&format=webp&cache=true`) : ''));
        if (imageUrl) {
          this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
          this.metaService.updateTag({ property: 'og:image', content: replaceHostname(imageUrl, this.shopHost) });
          this.metaService.updateTag({ property: 'og:image:alt', content: product.name });
          this.metaService.updateTag({ name: 'twitter:image', content: replaceHostname(imageUrl, this.shopHost) });
          this.metaService.updateTag({ name: 'twitter:image:alt', content: product.name });
        } else {
          this.metaService.updateTag({ name: 'twitter:card', content: 'summary' });
        }
        this.logger.debug('Updated metadata for product:', product.name);
    }

    private removeMetadata() {
        this.titleService.setTitle(`${this.storeSite?.name? this.storeSite.name : ''}`);
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }

    selectItemEvent(product: any) {
        if(product) {
            this.analyticsService.viewItem(product);
        }
    }

    viewCartFromNotification(closeFn: () => void) {
        this.stateService.setState('cartDrawerOpen', true);
        closeFn();
    }

    updateMSRP() {
        if (this.selectedVariant && this.selectedVariant.customFields?.MSRP && this.selectedVariant.customFields.MSRP !== 0) {
            this.selectedVariantMSRP = this.selectedVariant.customFields.MSRP;
            const price = this.selectedVariant.price;
            const msrp = this.selectedVariantMSRP;
            if (msrp && msrp > price) {
                this.discountPercentage = ((msrp - price) / msrp) * 100;
            } else {
                this.discountPercentage = null;
            }
        } else {
            this.selectedVariantMSRP = null;
            this.discountPercentage = null;
        }
    }

    onVariantChange(event: { variant: Variant }) {
        this.selectedVariant = event.variant;
        this.inStock = (this.selectedVariant.stockLevel !== 'OUT_OF_STOCK');
        this.updateMSRP();
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: { variantid: this.selectedVariant.id },
            queryParamsHandling: 'merge',
        });
    }
}
