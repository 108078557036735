
import { Inject, Injectable, PLATFORM_ID, NgZone } from '@angular/core';
import { Router, NavigationEnd, } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';
import { filter } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { stripHtml } from '../../../common/utils/strip-html';
import { Order, OrderLine, Product, ProductVariant } from '../../../../app/common/generated-types';
import { IAnalyticsService } from './analytics.interface';
import { BehaviorSubject, Observable } from 'rxjs';
import { replaceHostname } from '../../../common/utils/url-handlers';

declare global {
    interface Window {
        omnisend: any;
    }
}

interface OrderLineReduced {
    productVariant: ProductVariant,
    quantity: number,
}

@Injectable({
    providedIn: 'root'
})
export class OmniSendService implements IAnalyticsService {
    private isScriptLoaded = false;
    private scriptLoadedSubject: BehaviorSubject<boolean>;
    public scriptLoaded$: Observable<boolean>;
    private shopHost: string;

    constructor(@Inject(PLATFORM_ID) private platformId: object,
        private logger: NGXLogger,
        private router: Router,
        private ngZone: NgZone
    ) {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.scriptLoadedSubject = new BehaviorSubject<boolean>(false);
        this.scriptLoaded$ = this.scriptLoadedSubject.asObservable();
    }

    loadOmnisend(shopHost: string): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        this.shopHost = shopHost;
        if (!this.isScriptLoaded) {
            window.omnisend = window.omnisend || [];
            window.omnisend.push(['accountID', environment.omniSendAccountId]);
            window.omnisend.push(['shopHostname', shopHost]);
            window.omnisend.push(['track', '$pageViewed']);

            const script = document.createElement('script');
            script.type = 'text/javascript';
            script.async = true;
            //script.defer = true;
            script.src = 'https://omnisnippet1.com/inshop/launcher-v2.js';
            script.onload = () => {
                this.isScriptLoaded = true;
                this.scriptLoadedSubject.next(true);
                this.initializeTracking();
            };

            const firstScript = document.getElementsByTagName('script')[0];
            firstScript.parentNode?.insertBefore(script, firstScript);

        }
    }

    private initializeTracking() {
        this.router.events.pipe(
            filter((event): event is NavigationEnd => event instanceof NavigationEnd)
        ).subscribe((event: NavigationEnd) => {
            this.ngZone.runOutsideAngular(() => {
                setTimeout(() => {
                    if (event.urlAfterRedirects === '/') {
                        this.trackPageView(event.urlAfterRedirects);
                    }
                }, 1000);
            });
        });
    }

    private track(params: any): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        try {
            if (window.omnisend && window.omnisend.push) {
                window.omnisend.push(['track', ...params]);
                this.logger.debug('Omnisend push', JSON.stringify(['track', ...params]));
            } else {
                this.logger.error('Omnisend not initialized');
            }
        } catch (e) {
            this.logger.error('Omnisend track error', e);
        }
    }

    private trackPageView(url: string): void {
        this.track(['$pageViewed']);
        this.logger.info('Tracked page view:', url);
    }

    private orderLineFormat(orderLine: OrderLineReduced) {
        const { productVariant } = orderLine;
        const product = productVariant.product;
        const description = product.customFields?.OGDescription ? product.customFields?.OGDescription :
            (product.customFields?.ProductSubtitle ? product.customFields?.ProductSubtitle :
                (stripHtml(product.description, this.platformId)));
        const productImageUrl = product.featuredAsset ? (`${product.featuredAsset?.preview}?preset=medium`) : '';
        const productStrikeThroughPrice = productVariant.customFields?.MSRP ? productVariant.customFields?.MSRP / 100.0 : undefined;
        const productVariantImageUrl = productVariant.featuredAsset ? (`${productVariant.featuredAsset?.preview}?preset=medium`) : productImageUrl;
        const price = productVariant.price / 100.0;
        return {
            "productDescription": description,
            "productDiscount": productStrikeThroughPrice ? productStrikeThroughPrice - price : 0,
            "productID": product.id,
            "productImageURL": replaceHostname(productImageUrl, this.shopHost),
            "productPrice": productVariant.price / 100.0,
            "productQuantity": orderLine.quantity,
            "productSKU": productVariant.sku,
            "productStrikeThroughPrice": productStrikeThroughPrice,
            "productTitle": product?.name,
            "productURL": `${this.shopHost}/dp/${product.slug}`,
            "productVariantID": productVariant.id,
            "productVariantImageURL": replaceHostname(productVariantImageUrl, this.shopHost),
        };
    }

    public initialize(shopHost: string): void {
        this.loadOmnisend(shopHost);
    }

    public setEmail(email: string): void {
        if (!isPlatformBrowser(this.platformId)) {
            return;
        }
        if (window.omnisend && window.omnisend.identifyContact) {
            this.logger.debug('identifyContact', email);
            window.omnisend.identifyContact({
                "email": email
            });
        }
    }

    public viewItem(product: Product): void {
        try {
            const variant = product.variants[0];
            const description = product.customFields?.OGDescription ? product.customFields?.OGDescription :
                (product.customFields?.ProductSubtitle ? product.customFields?.ProductSubtitle :
                    (stripHtml(product.description, this.platformId)));
            const imageUrl = product.featuredAsset ? (`${product.featuredAsset?.source}?preset=medium`) : '';
            const event = {
                origin: "api",
                properties: {
                    product: {
                        id: product.id,
                        currency: 'USD',
                        price: variant.price / 100.0,
                        oldPrice: variant.customFields?.MSRP ? variant.customFields.MSRP / 100 : undefined,
                        title: product?.name,
                        description: description,
                        imageUrl: replaceHostname(imageUrl, this.shopHost),
                        url: `${this.shopHost}/product/${product.slug}`,
                        status: variant.stockLevel === 'OUT_OF_STOCK' ? 'outOfStock' : 'inStock',
                    }
                }
            };
            this.track(["viewed product", event]);
            this.logger.info('Tracked viewed product:', event);
        } catch (e) {
            this.logger.error('Error in OmniSendService::trackProductView', e);
        }
    }

    public selectItem(product: Product): void {}

    public addToCart(product: Product, variant: ProductVariant, quantity: number, order: Order): void {
        try {
            const orderLine = order.lines.find(line => {
                this.logger.debug(line.productVariant?.id, variant?.id);
                return line.productVariant?.id === variant?.id;
              });

            const event = {
                origin: "api",
                properties: {
                    abandonedCheckoutURL: `${this.shopHost}/checkout`,
                    cartID: order.code,
                    currency: "USD",
                    lineItems: order.lines.map(line => this.orderLineFormat(line)),
                    addedItem: orderLine ? this.orderLineFormat(orderLine) : undefined,
                    value: order.total / 100.0
                }
            };

            this.track(["added product to cart", event]);
            this.logger.info('Tracked added product to cart:', event);
        } catch (e) {
            this.logger.error('Error in OmniSendService::trackAddedProductToCart', e);
        }
    }

    public beginCheckout(order: Order, callback?: any): void {
        try {
            const event = {
                origin: "api",
                properties: {
                    abandonedCheckoutURL: `${this.shopHost}/checkout/shipping`,
                    cartID: order.code,
                    currency: "USD",
                    lineItems: order.lines.map(line => this.orderLineFormat(line)),
                    value: order.total / 100.0
                }
            };
            this.track(["started checkout", event]);
            this.logger.info('Tracked started checkout:', event);
        } catch (e) {
            this.logger.error('Error in OmniSendService::trackStartCheckout', e);
        }
    }

    public addShippingInfo(order: any, callback?: any): void {}

    public purchase(order: any, callback?: any): void {}
}