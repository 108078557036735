import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { combineLatest, Subject } from 'rxjs';
import { filter, map, take, takeUntil } from 'rxjs/operators';
import { GetStoreInfoQuery } from '../../common/generated-types';
import { DataService } from '../../core/providers/data/data.service';
import { notNullOrUndefined } from '../../common/utils/not-null-or-undefined';
import { safeJSONParse } from '../../common/utils/safe-json-parser';
import { PolicyContentData } from '../../common/interfaces';
import { StateService } from '../../core/providers/state/state.service';
import { CanonicalService } from '../../core/providers/canonical/canonical.service';

@Component({
  selector: 'vsf-store-policy-page',
  templateUrl: './store-policy-page.component.html',
  styleUrls: ['./store-policy-page.component.scss'],
})
export class StorePolicyPageComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  policyContent: PolicyContentData | null = null;
  canonicalURL = '';
  identifier = '';
  shopHost = '';
  storeInfo: GetStoreInfoQuery['storeSite'];

  constructor(@Inject(PLATFORM_ID) private platformId: object,
              private route: ActivatedRoute,
              private router: Router,
              private canonicalService: CanonicalService,
              private dataService: DataService,
              private changeDetector: ChangeDetectorRef,
              private stateService: StateService,
              private logger: NGXLogger) {
  }

  ngOnInit(): void {
    this.logger.info('[Store Policy page] init');
    const storeInfo$ = this.stateService.select(state => state.storeInfo).pipe(take(1));
    const storePath$ = this.stateService.select(state => state.storePath).pipe(take(1));
    const policyTypePath$ = this.route.url.pipe(map(urlSegments => urlSegments[urlSegments.length - 1].path));
    const shopHost$ = this.stateService.select(state => state.shopHost).pipe(take(1));
    combineLatest([storeInfo$, storePath$, policyTypePath$, shopHost$]).pipe(takeUntil(this.destroy$))
    .subscribe(([storeInfo, storePath, policyTypePath, shopHost]) => {
        this.logger.info('[Policy page] identifier:', this.storeInfo?.identifier, 'type:', policyTypePath);
        this.storeInfo = storeInfo;
        this.identifier = storeInfo?.identifier || '';
        this.canonicalURL = `${storePath}/store-policy/${policyTypePath}`;
        this.shopHost = shopHost;
        this.canonicalService.setCanonicalURL(`${this.shopHost}${this.canonicalURL}`);
        console.log(`StoreMainPageComponent::ngOnInit::identifier`, this.identifier, this.storeInfo);
        const policyPageListJson = this.storeInfo?.policyContentList || [];
        if(policyPageListJson?.length > 0) {
            const policyPageList = policyPageListJson?.map((jsonString:any) => safeJSONParse<PolicyContentData>(jsonString, this.logger))
            .filter(notNullOrUndefined);
            this.policyContent = policyPageList.find((policyPage: PolicyContentData) => policyPage?.path === policyTypePath) || null;
            if(this.policyContent) {
                this.changeDetector.markForCheck();
            } else {
                this.logger.error('Failed to find policy content for path:', policyTypePath);
                this.router.navigate(['/store', this.identifier]);
            }
        }
    });
  }

  ngOnDestroy(): void {
    this.canonicalService.removeCanonicalURL();
  }
}