import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';

@Component({
  selector: 'vsf-product-review-indicator',
  template: `
    <div class="review-indicator">
      <div class="flex items-center space-x-1">

        <ng-container *ngFor="let _ of fullStars">
          <svg class="size-5 shrink-0 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z" clip-rule="evenodd" />
          </svg>
        </ng-container>

        <ng-container *ngIf="halfStar">
          <svg class="size-5 shrink-0 text-yellow-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <!-- Define a clipPath that covers only half of the star -->
            <defs>
              <clipPath id="half-star-clip">
                <!-- This rectangle covers the left half of the viewBox -->
                <rect width="50%" height="100%" x="0" y="0"/>
              </clipPath>
            </defs>
            <path
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
              fill="none"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linejoin="round"
              class="text-yellow-400"
            >
            </path>
            <path
              d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z"
              clip-path="url(#half-star-clip)"
              fill="currentColor"
              class="text-yellow-400"
            >
            </path>
          </svg>
        </ng-container>

        <ng-container *ngFor="let _ of emptyStars">
          <svg class="size-5 shrink-0 text-gray-300" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" data-slot="icon">
            <path fill-rule="evenodd" d="M10.868 2.884c-.321-.772-1.415-.772-1.736 0l-1.83 4.401-4.753.381c-.833.067-1.171 1.107-.536 1.651l3.62 3.102-1.106 4.637c-.194.813.691 1.456 1.405 1.02L10 15.591l4.069 2.485c.713.436 1.598-.207 1.404-1.02l-1.106-4.637 3.62-3.102c.635-.544.297-1.584-.536-1.65l-4.752-.382-1.831-4.401Z" clip-rule="evenodd" />
          </svg>
        </ng-container>
      </div>
      <div *ngIf="displayReviewNumbers" class="review-number">{{ inputReviewNumber }} reviews</div>
    </div>
  `,
  styles: [`
    .orange {
      color: orange;
    }
    .hollow {
      color: lightgray;
    }
    .mr-2 {
      margin-right: 0.5rem;
    }
    .review-indicator {
      display: flex;
      align-items: center;
    }
    .review-number {
      margin-left: 1rem;
    }
  `]
})
export class ProductReviewIndicatorComponent implements OnInit, OnChanges {
  @Input() inputScore: number = 5.0;
  @Input() inputReviewNumber: number = 1;
  @Input() displayReviewNumbers: boolean = true;

  fullStars: number[] = [];
  halfStar: boolean = false;
  emptyStars: number[] = [];

  constructor(private logger: NGXLogger) { }

  ngOnInit(): void {
      if (!this.inputScore) {
          this.inputScore = 5;
      }
      if (!this.inputReviewNumber) {
          this.inputReviewNumber = 1;
      }
      this.calculateStars();
  }

  ngOnChanges() {
      this.calculateStars();
  }

  calculateStars() {
    this.logger.debug(`[ReviewIndicatorComponent.ngOnChanges]score: ${this.inputScore}, reviewNumber: ${this.inputReviewNumber}`);
    const fullStarCount = Math.floor(this.inputScore) + (this.inputScore % 1 > 0.67 ? 1 : 0);
    const hasHalfStar = (this.inputScore % 1 >= 0.33) && (this.inputScore % 1 <= 0.67);
    this.fullStars = Array(fullStarCount).fill(0);
    this.halfStar = hasHalfStar;
    this.emptyStars = Array(5 - fullStarCount - (hasHalfStar ? 1 : 0)).fill(0);
    this.logger.debug(`[ReviewIndicatorComponent.ngOnChanges]fullStars: ${this.fullStars}, halfStar: ${this.halfStar}, emptyStars: ${this.emptyStars}`);
  }
}
