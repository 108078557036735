<div class="collections-carousel">
  <!-- If there's only one group of collections -->
  <div *ngIf="groupedCollections.length === 1" class="group-name">
    <vsf-two-collection-card-v2
      [collectionA]="groupedCollections[0][0]"
      [collectionB]="groupedCollections[0][1]"
      [colorConfig]="colorConfig"
      class="card">
    </vsf-two-collection-card-v2>
  </div>

  <!-- If there are multiple groups of collections -->
  <div *ngIf="groupedCollections.length > 1" class="group-name min-h-[200px]">
    <!-- Check if browser, else show fallback -->
    <ng-container *ngIf="isBrowser; else fallbackSlider">
      <swiper-container
        appSwiper
        #swiper
        [slidesPerView]="1"
        [slidesPerGroup]="1"
        [direction]="swipeDirection"
        spaceBetween="10"
        [pagination]="{ clickable: true }"
        [centeredSlides]="true"
        auto-height="true"
        rewind="true"
        class="flex object-cover"
      >
        <swiper-slide *ngFor="let group of groupedCollections; let i = index">
          <div class="flex flex-row justify-center">
            <div class="flex flex-wrap">
              <div class="slide-item">
                <vsf-two-collection-card-v2
                  [collectionA]="group[0]"
                  [collectionB]="group[1]"
                  [colorConfig]="colorConfig"
                  class="card">
                </vsf-two-collection-card-v2>
              </div>
            </div>
          </div>
        </swiper-slide>
      </swiper-container>
    </ng-container>

    <!-- Fallback template for SSR (non-browser) rendering -->
    <ng-template #fallbackSlider>
      <!-- Show just the first group of collections as a static card -->
      <!-- Adjust this as needed, e.g., you could choose a different group or styling -->
      <div class="flex flex-row justify-center">
        <div class="flex flex-wrap">
          <div class="slide-item">
            <vsf-two-collection-card-v2
              [collectionA]="groupedCollections[0][0]"
              [collectionB]="groupedCollections[0][1]"
              [colorConfig]="colorConfig"
              class="card">
            </vsf-two-collection-card-v2>
          </div>
        </div>
      </div>
    </ng-template>
  </div>
</div>