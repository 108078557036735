import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { StateService } from '../../providers/state/state.service';

@Component({
    selector: 'vsf-product-search-bar',
    templateUrl: './product-search-bar.component.html',
    styleUrls: ['./product-search-bar.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductSearchBarComponent implements OnInit, OnDestroy {
    /** If true, searches as you type */
    @Input() autoSearch = false;
    @Input() storeIdentifier: string;
    @Input() isMobile : boolean;
    private destroy$ = new Subject<void>();
    showOverlay = false;
    baseUrl = '';

    searchTerm = new UntypedFormControl('');
    private subscription: Subscription;
    constructor(private route: ActivatedRoute,
                private stateService: StateService,
                private router: Router) { }

    ngOnInit() {
        this.stateService.select(state => state.storePath).pipe(
            takeUntil(this.destroy$)
        ).subscribe(storePath => {
            console.log('[ProductSearchBarComponent]storePath:', storePath);
            this.baseUrl = storePath;
        });
        if (this.autoSearch) {
            this.subscription = this.searchTerm.valueChanges.pipe(
                debounceTime(250),
            ).subscribe(term => this.doSearch(term));
        }
    }

    ngOnDestroy(): void {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.destroy$.next();
        this.destroy$.complete();
    }

    doSearch(term: string) {
        if (term || this.showOverlay) {

            console.log('base url:', this.baseUrl);
            this.router.navigate([this.baseUrl, 'search'], {
                queryParams: { search: term },
                relativeTo: this.route,
                queryParamsHandling: 'merge',
            });
        }
        this.showOverlay = !this.showOverlay; // Toggle overlay visibility
        this.searchTerm.setValue('', { emitEvent: false });
    }

    closeOverlay() {
        this.showOverlay = false;
    }
}
