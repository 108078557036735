<div *ngIf="readyToDisplay$ | async" class="contact-container">
  <!-- Contact Poster Section -->
  <div *ngIf="posterAsset" class="contact-poster">
    <img [src]="posterAsset | assetPreview:'full'"
        [ngClass]="isMobile? 'object-cover w-full h-full aspect-[16/9]' : ''"
        alt="Poster for Contact Page" />
  </div>

  <div *ngIf="contactFormType === 'email-form'" class="info-text text-center mt-8 mb-12">
    <span>Please use the contact form below to send us any questions, comments, or suggestions.</span>
    <br>
    <span>We look forward to hearing from you!</span>
  </div>
  <div class="contact-main flex flex-row justify-center items-center">
    <!-- Contact Form Section -->
    <div *ngIf="contactFormType === 'email-form'" class="contact-form">
      <form (ngSubmit)="onSubmit()" #contactForm="ngForm">
        <div class="form-group">
          <input type="text"
                 id="name"
                 name="name"
                 placeholder="First Name"
                 [(ngModel)]="contact.name" required />
        </div>
        
        <div class="form-group">
          <input type="text"
                 id="surname"
                 name="surname"
                 placeholder="Last Name"
                 [(ngModel)]="contact.surname" required />
        </div>
        
        <div class="form-group">
          <input type="email"
                 id="email"
                 name="email"
                 placeholder="Email"
                 [(ngModel)]="contact.email" required />
        </div>
        
        <div class="form-group">
          <textarea id="message"
                    name="message"
                    placeholder="Please let us know what's on your mind. Have a question for us? Ask Away."
                    [(ngModel)]="contact.message"
                    #message="ngModel"
                    minlength="1"
                    maxlength="600"
                    (ngModelChange)="onMessageInput($event)"
                    required></textarea>
          <div class="character-counter"
               [ngClass]="{ 'text-red': contact.message.length > maxMessageLength }">
            {{ contact.message.length }} of 600 max characters
          </div>
        </div>

        <div class="flex justify-center text-center items-center mt-8 mb-20">
          <button class="submit-button"
              [ngStyle]="{
                'background-color': !contactForm.form.valid ? '#ccc' : (colorConfig ? colorConfig.secondaryColor : '#B6E7B0'),
                'color': colorConfig ? '#000000' : '#FFFFFF'
              }"
              type="submit"
              [disabled]="!contactForm.form.valid"
          >
              Submit
          </button>
        </div>
        
      </form>  
    </div>

    <!-- Contact Featured Image Section -->
    <div *ngIf="featuredAsset && !isMobile" class="contact-featured-image items-center ml-2">
      <img [src]="featuredAsset | assetPreview:'full'" alt="Featured Image" />
    </div>
  </div>
</div>
