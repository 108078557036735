<div *ngIf="readyToDisplay$ | async" class="about-container">
  <!-- About Poster Section -->
  <div *ngIf="posterAsset" class="about-poster">
    <img [src]="posterAsset | assetPreview:'full'"
        [ngClass]="isMobile? 'object-cover w-full h-full aspect-[16/9]' : ''"
        alt="Poster for About Page" />
  </div>
  <!-- About Title Section -->
  <div *ngIf="title" class="about-title">
    <p>{{ title }}</p>
  </div>
  <!-- About Text Content Section -->
  <div *ngIf="text" class="about-text px-4">
    <div [innerHtml]="text | safeHtml"></div>
  </div>

  <div class="flex flex-col md:flex-row flex-wrap items-center">
    <!-- Featured Image Section -->
    <div
      *ngIf="featuredAsset"
      class="about-featured-image overflow-hidden order-1 md:order-2 w-full md:w-1/2 md:p-2"
    >
      <img
        [src]="featuredAsset | assetPreview:'full'"
        alt="Featured Image"
        class="overflow-hidden w-full h-full object-contain"
      />
    </div>

    <!-- Citation Section -->
    <div
      *ngIf="citation"
      class="flex flex-col mt-2 order-2 md:order-1 w-full md:w-1/2 px-4 md:p-2"
    >
      <div class="quote-left-container">
        <div class="citation-text">
          <p [innerHtml]="citation | safeHtml"></p>
        </div>
    </div>
  </div>
</div>