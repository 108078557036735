import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreInfoQuery } from '../../../common/generated-types';
import { take } from 'rxjs/operators';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { ColorConfig, ContactPageData } from '../../../common/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { StateService } from '../../providers/state/state.service';
import { CanonicalService } from '../../providers/canonical/canonical.service';

interface ContactMessage {
    name: string;
    surname: string;
    email: string;
    message: string;
};

@Component({
    selector: 'vsf-contact-page',
    templateUrl: './contact-page.component.html',
    styleUrls: ['./contact-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactPageComponent implements OnInit, OnDestroy {
    identifier: string;
    storeInfo: GetStoreInfoQuery['storeSite'];
    vendorEmail: string;
    contactPage: ContactPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    contactFormType: string;
    featuredAsset: Asset;
    maxMessageLength = 600;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);

    contact: ContactMessage = {
        name: '',
        surname: '',
        email: '',
        message: ''
    };

    constructor(private logger: NGXLogger,
        private canonicalService: CanonicalService,
        private stateService: StateService) {}

    ngOnInit(): void {
        const isMobile$ = this.stateService.select(state => state.isMobile);
        const storeInfo$ = this.stateService.select(state => state.storeInfo);
        const storePath$ = this.stateService.select(state => state.storePath);
        const shopHost$ = this.stateService.select(state => state.shopHost);
        combineLatest([isMobile$, storeInfo$, storePath$, shopHost$]).pipe(take(1)).subscribe(([isMobile, storeInfo, storePath, shopHost]) => {
            this.isMobile = isMobile;
            this.storeInfo = storeInfo;
            this.identifier = storeInfo?.identifier || '';
            this.vendorEmail = this.storeInfo?.seller?.customFields?.email || '';
            this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
            this.contactPage = this.storeInfo?.contactPageData as ContactPageData;
            if(this.contactPage) {
                this.posterAsset = this.contactPage.posterAsset || this.posterAsset;
                this.contactFormType = this.contactPage.contactFormType || this.contactFormType;
                this.featuredAsset = this.contactPage.featuredAsset || this.featuredAsset;
            }
            const canonicalURL = `${storePath}/contact`;
            this.canonicalService.setCanonicalURL(`${shopHost}${canonicalURL}`);
            this.readyToDisplay$.next(true);
        });

    }

    ngOnDestroy() {
        this.canonicalService.removeCanonicalURL();
    }

    onMessageInput(message: string): void {
        this.contact.message = message;
    }

    onSubmit(): void {
        const mailtoLink = `mailto:${this.vendorEmail}?subject=Contact%20Form%20Submission&body=Name:%20${encodeURIComponent(this.contact.name)}%0A
        Surname:%20${encodeURIComponent(this.contact.surname)}%0A
        Email:%20${encodeURIComponent(this.contact.email)}%0A
        Message:%20${encodeURIComponent(this.contact.message)}`;

        if(window) {
            window.location.href = mailtoLink;
        }
    }
}
