import { ChangeDetectionStrategy, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreInfoQuery } from '../../../common/generated-types';
import { take } from 'rxjs/operators';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { AboutPageData, ColorConfig } from '../../../common/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { StateService } from '../../providers/state/state.service';
import { CanonicalService } from '../../providers/canonical/canonical.service';

@Component({
    selector: 'vsf-about-page',
    templateUrl: './about-page.component.html',
    styleUrls: ['./about-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.None,
})
export class AboutPageComponent implements OnInit, OnDestroy {
    identifier: string;
    storeInfo: GetStoreInfoQuery['storeSite'];
    aboutPage: AboutPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    title: string;
    text: string;
    citation: string;
    featuredAsset: Asset;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);

    constructor(private logger: NGXLogger,
                private canonicalService: CanonicalService,
                private stateService: StateService) { }

    ngOnInit(): void {
        const isMobileSub = this.stateService.select(state => state.isMobile);
        const storeInfoSub = this.stateService.select(state => state.storeInfo);
        const storePathSub = this.stateService.select(state => state.storePath);
        const shopHostSub = this.stateService.select(state => state.shopHost);
        combineLatest([isMobileSub, storeInfoSub, storePathSub, shopHostSub]).pipe(take(1)).subscribe(([isMobile, storeInfo, storePath, shopHost]) => {
            this.isMobile = isMobile;
            this.storeInfo = storeInfo;
            this.identifier = storeInfo?.identifier || '';
            this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
            this.aboutPage = this.storeInfo?.aboutPageData as AboutPageData;
            if(this.aboutPage) {
                this.posterAsset = this.aboutPage.posterAsset || this.posterAsset;
                this.title = this.aboutPage.title || this.title;
                this.text = this.aboutPage.text || this.text;
                this.citation = this.aboutPage.citation || this.citation;
                this.featuredAsset = this.aboutPage.featuredAsset || this.featuredAsset;
            }
            const canonicalURL = `${storePath}/about`;
            this.canonicalService.setCanonicalURL(`${shopHost}${canonicalURL}`);
            this.readyToDisplay$.next(true);
        });
    }

    ngOnDestroy() {
        this.canonicalService.removeCanonicalURL();
    }
}
