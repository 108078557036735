<ng-template [ngIf]="item">
    <div *ngIf="layoutMode==='vertical'" class="flex flex-col">
        <a class="block" prefetch='intent' [routerLink]="[productBaseUrl, item.slug]" [queryParams]="{ variantid: item.productVariantId }">
            <div class="w-full h-full full-size">
                <img
                    *ngIf="!featuredAsset?.videoUrl"
                    loading="lazy"
                    [src]="featuredAsset | assetPreview:'small'"
                    [alt]="itemName"
                    style="pointer-events: none;"
                    [width]="featuredAsset?.width || 175"
                    [height]="featuredAsset?.height || 175"
                    class="w-full h-full object-cover rounded-xl aspect-[1/1]"
                />
                <vsf-vimeo-player
                    *ngIf="featuredAsset?.videoUrl && cardWidth"
                    [source]="featuredAsset?.videoUrl"
                    [width]="cardWidth"
                    [height]="cardHeight">
                </vsf-vimeo-player>
            </div>
            <div class="product-name font-bold mt-2 mb-1 text-[#2E3742]" [title]="itemName">
                {{ itemName }}
            </div>
            <div *ngIf="displaySubtitle" class="product-subtitle mb-1 text-[#95A1AF]" [title]="item.productSubtitle" >
                {{ item.productSubtitle || '&nbsp;' }}
            </div>
            <div *ngIf="displayPrice" class="flex flex-col product-price font-medium font-semibold text-[#2E3742]">
                <ng-container *ngIf="msrp && discountPercentage; else onlyPrice">
                    <div class="text-base">
                        <span class="text-grey-500 line-through">
                            {{ msrp | formatPrice }}
                        </span>
                        <span class="text-red-500 ml-2">
                            {{ itemPrice | formatPrice }} ({{ discountPercentage | number:'1.0-0' }}% off)
                        </span>
                    </div>
                </ng-container>
                <ng-template #onlyPrice>
                    <div>
                        <div *ngIf="item.price.__typename === 'SinglePrice'">
                            {{ itemPrice | formatPrice }}
                        </div>
                        <div *ngIf="item.price.__typename === 'PriceRange'">
                            <ng-template [ngIf]="item.price.min === item.price.max" [ngIfElse]="range">
                                {{ itemPrice | formatPrice }}
                            </ng-template>
                            <ng-template #range>
                                From {{ item.price.min | formatPrice }}
                            </ng-template>
                        </div>
                    </div>
                </ng-template>
            </div>
        </a>
        <div *ngIf="displayBuyButton" class="mt-2 w-1/2">
            <button (click)="addToCartAndOpenDrawer()"
                class="bg-[#29319C] text-white px-8 py-2 whitespace-nowrap rounded-lg border border-gray-300 hover:bg-zinc-600 focus:outline-none pointer-events-auto"
                [ngClass]="{
                    'bg-zinc-600': (inFlight|| !inStock),
                    'bg-[#29319C] hover:bg-zinc-600': !inFlight
                }"
                [disabled]="inFlight || !inStock"
            >
                {{inStock?'Shop Now':'Sold Out'}}
            </button>
        </div>
    </div>
    <div *ngIf="layoutMode==='horizontal'" class="flex flex-row w-full h-full">
        <div class="w-full">
            <div class="w-full h-full full-size">
                <a prefetch='intent' [routerLink]="[productBaseUrl, item.slug]" [queryParams]="{ variantid: item.productVariantId }">
                    <img
                        *ngIf="!featuredAsset?.videoUrl"
                        loading="lazy"
                        [src]="featuredAsset | assetPreview:'small'"
                        [alt]="itemName"
                        style="pointer-events: none;"
                        [width]="featuredAsset?.width || 175"
                        [height]="featuredAsset?.height || 175"
                        class="w-full h-full object-cover rounded-xl aspect-[1/1]"
                    />
                    <vsf-vimeo-player
                        *ngIf="featuredAsset?.videoUrl && cardWidth"
                        [source]="featuredAsset?.videoUrl"
                        [width]="cardWidth"
                        [height]="cardHeight">
                    </vsf-vimeo-player>
                </a>
            </div>
        </div>
        <div class="w-2/3 pl-2 flex flex-col">
            <div class="flex-grow">
                <div class="h-2"></div>
                <div class="font-bold text-[#2E3742] product-name">
                    {{ itemName }}
                </div>
                <div *ngIf="item.productSubtitle" class="product-subtitle text-[#95A1AF]">
                    {{ item.productSubtitle || '&nbsp;' }}
                </div>
                <div *ngIf="displayPrice" class="flex text-base font-medium font-semibold text-[#2E3742]">
                    <ng-container *ngIf="msrp && discountPercentage; else onlyPrice">
                        <div class="text-base">
                            <span class="text-grey-500 line-through">{{ msrp | formatPrice }}</span>
                            <span class="text-red-500 ml-2">{{ itemPrice | formatPrice }} ({{ discountPercentage | number:'1.0-0' }}% off)</span>
                        </div>
                    </ng-container>
                    <ng-template #onlyPrice>
                        <div *ngIf="item.price.__typename === 'SinglePrice'">
                            {{ itemPrice | formatPrice }}
                        </div>
                        <div *ngIf="item.price.__typename === 'PriceRange'">
                            <ng-template [ngIf]="item.price.min === item.price.max" [ngIfElse]="range">
                            {{ itemPrice | formatPrice }}
                            </ng-template>
                            <ng-template #range>
                            From {{ item.price.min | formatPrice }}
                            </ng-template>
                        </div>
                    </ng-template>
                </div>
            </div>
            <div *ngIf="displayBuyButton" class="w-full mt-2">
                <button (click)="addToCartAndOpenDrawer()"
                    class="bg-[#29319C] text-white px-8 py-2 whitespace-nowrap rounded-lg border border-gray-300 hover:bg-zinc-600 focus:outline-none pointer-events-auto"
                    [ngClass]="{
                        'bg-zinc-600': (inFlight|| !inStock),
                        'bg-[#29319C] hover:bg-zinc-600': !inFlight
                    }"
                    [disabled]="inFlight || !inStock"
                    [style.width.px]="200">
                    {{inStock?'Shop Now':'Sold Out'}}
                </button>
            </div>
        </div>
    </div>
</ng-template>
