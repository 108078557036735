import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, Input, OnInit, PLATFORM_ID, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { GetReviewForProductQuery, GetReviewForProductQueryVariables, Product, ProductReview, SortOrder } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { GET_REVIEWS_FOR_PRODUCT } from '../../../common/graphql/documents.graphql';
import { take } from 'rxjs/operators';
import { SwiperContainer } from 'swiper/element';
import { CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { ModalService } from '../../../core/providers/modal/modal.service';
import { ProductReviewModalComponent } from '../../../shared/components/product-review-modal/product-review-modal.component';
import Swiper from 'swiper';
import { isPlatformBrowser } from '@angular/common';
import { Review } from '../../../common/interfaces';

@Component({
  selector: 'vsf-porduct-review-section',
  templateUrl: './review-section.component.html',
  styleUrls: ['./review-section.component.scss']
})
export class ReviewSectionComponent implements OnInit, AfterViewInit  {
  @ViewChild('desktopReviewSwiper') swiper!: ElementRef<SwiperContainer>;
  @ViewChild('mobileReviewSwiper') mobileSwiper!: ElementRef<SwiperContainer>;
  @ViewChildren('reviewContent') reviewContentElements!: QueryList<ElementRef>;

  @Input() productId: string;
  @Input() reviewRating: number;
  @Input() reviewCount: number;
  @Input() featuredReview: ProductReview;
  @Input() isMobile: boolean;

  customerSay: string;
  reviews: Review[] = [];
  slidesPerView: number = 3;
  reviewMaxLines: number = 10;
  overflow: boolean[] = [];
  private checkInterval: NodeJS.Timeout;
  isBrowser: boolean;

  constructor(private dataService: DataService,
              private modalService: ModalService,
              private changeDetector: ChangeDetectorRef,
              private logger: NGXLogger,
              @Inject(PLATFORM_ID) private platformId: object) {
                this.isBrowser = isPlatformBrowser(this.platformId);
              }

  ngOnInit(): void {
    this.customerSay = this.featuredReview.body || '';
    this.dataService.query<GetReviewForProductQuery, GetReviewForProductQueryVariables>(GET_REVIEWS_FOR_PRODUCT,
      {
        productId: this.productId,
        options: {
          sort: {
            rating: SortOrder.DESC
          }
        }
      }).pipe(take(1)).subscribe(data => {
        this.logger.info('Product reviews:', data.product?.reviews);
        this.reviews = data.product?.reviews?.items.map(review => ({
          name: review.authorName,
          rating: review.rating,
          content: review.body,
          summary: review.summary,
        })) || [];
      });
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      const swiperInstance: Swiper = this.isMobile? this.mobileSwiper.nativeElement.swiper : this.swiper.nativeElement.swiper;
      if(swiperInstance) {
        swiperInstance.on('slideChange', () => {
          this.checkOverflowForActiveSlide(swiperInstance);
        });
      }
      this.checkInterval = setInterval(() => {
        if(swiperInstance) {
          this.checkOverflowForActiveSlide(swiperInstance);
        }
      }, 200);
    }
  }

  ngOnDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval);
    }
  }

  getStars(rating: number): string[] {
    return Array(5).fill(0).map((_, index) => index < rating ? 'filled' : 'empty');
  }

  swipeToNext() {
    if(!this.swiper.nativeElement.swiper.isEnd) {
      this.swiper.nativeElement.swiper.slideNext();
    }
  }

  swipeToPrev() {
    if(!this.swiper.nativeElement.swiper.isBeginning) {
      this.swiper.nativeElement.swiper.slidePrev();
    }
  }

  checkOverflowForActiveSlide(swiperInstance: Swiper) {
    if(swiperInstance?.slides?.length > 0) {
      this.reviewContentElements.forEach((ref, index) => {
        const isOverflowing = ref.nativeElement.scrollHeight > ref.nativeElement.clientHeight;
        this.overflow[index] = isOverflowing;
      });
      if (this.checkInterval) {
        clearInterval(this.checkInterval);
      }
      this.changeDetector.detectChanges();
    }
  }

  openReviewDialog(review: Review) {
    this.modalService.fromComponent(ProductReviewModalComponent,
      {
          size: this.isMobile? 'sm': 'md',
          locals: {
            review,
          },
      }
    ).subscribe(async (result) => {});
  }
}