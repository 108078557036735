import { ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { combineLatest, Observable } from 'rxjs';
import { take, map } from 'rxjs/operators';
import { GetChannelHeroQuery } from '../../../common/generated-types';
import { DataService } from '../../../core/providers/data/data.service';
import { GET_CHANNEL_HERO } from '../../../common/graphql/documents.graphql';
import { StateService } from '../../../core/providers/state/state.service';
import { stripHtml } from '../../../common/utils/strip-html';
import { NGXLogger } from 'ngx-logger';
import { replaceHostname } from '../../../common/utils/url-handlers';
import { CanonicalService } from '../../../core/providers/canonical/canonical.service';

@Component({
    selector: 'vsf-home-page',
    templateUrl: './home-page.component.html',
    styleUrls: ['./home-page.component.scss'],
})
export class HomePageComponent implements OnInit, OnDestroy {
    channel$: Observable<GetChannelHeroQuery['activeChannel']>;
    isMobile: boolean | null = null;
    shopHost: string;
    channelHeroSection: any | null;
    currentCanonicalURL: string | null = null;

    constructor(
        private metaService: Meta,
        private dataService: DataService,
        private logger: NGXLogger,
        private stateService: StateService,
        private titleService: Title,
        private canonicalService: CanonicalService,
        private changeDetector: ChangeDetectorRef,
        @Inject(PLATFORM_ID) private platformId: object,
    ) { }

    ngOnInit() {
        const isMobileSub$ = this.stateService.select(state => state.isMobile).pipe(take(1));
        const shopHostSub$ = this.stateService.select(state => state.shopHost).pipe(take(1));
        this.channel$ = this.dataService.query<GetChannelHeroQuery>(GET_CHANNEL_HERO)
            .pipe(map((data:any) => data.activeChannel), take(1));
        combineLatest([this.channel$, isMobileSub$, shopHostSub$]).subscribe(([activeChannel, isMobile, shopHost]) => {
            this.isMobile = isMobile;
            this.shopHost = shopHost;
            this.channelHeroSection = activeChannel?.heroSection;
            const canonicalURL = '';
            this.canonicalService.setCanonicalURL(`${this.shopHost}${canonicalURL}`);
            this.currentCanonicalURL = canonicalURL;
            this.updateHomePageMetadata(activeChannel);
            this.changeDetector.detectChanges();
        });
    }

    ngOnDestroy(): void {
        this.removeHomePageMetadata();
        if (this.currentCanonicalURL) {
            this.canonicalService.removeCanonicalURL();
            this.currentCanonicalURL = null;
        }
    }

    private updateHomePageMetadata(channel: GetChannelHeroQuery['activeChannel']) {
        if (!channel) return;
        const heroSection = channel?.heroSection;
        const titleHtml = heroSection?.title || '';
        const descriptionHtml = heroSection?.subtitle || '';
        const title = stripHtml(titleHtml, this.platformId) || 'Welcome to DealOmega';
        const description = stripHtml(descriptionHtml, this.platformId) || 'Discover amazing deals and products at DealOmega';
        // Set the page title
        this.titleService.setTitle(`${title}`);
        // Set meta description
        this.metaService.updateTag({ name: 'description', content: description });
        // Set Open Graph meta tags
        this.metaService.updateTag({ property: 'og:title', content: title });
        this.metaService.updateTag({ property: 'og:description', content: description });
        this.metaService.updateTag({ property: 'og:type', content: 'website' });
        this.metaService.updateTag({ property: 'og:url', content: this.shopHost });
        // If StoreHeroImages is available and is an array, use the first image
        if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
            const imageUrl = `${heroSection?.medias[0].preview}?preset=large`;
            this.metaService.updateTag({ property: 'og:image', content: replaceHostname(imageUrl, this.shopHost) });
        }
        // If there's a hero video, you might want to include it in the metadata
        if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'VIDEO') {
          this.metaService.updateTag({ property: 'og:video', content: heroSection?.medias[0].source });
        }
        // Set Twitter Card meta tags
        this.metaService.updateTag({ name: 'twitter:card', content: 'summary_large_image' });
        this.metaService.updateTag({ name: 'twitter:title', content: title });
        this.metaService.updateTag({ name: 'twitter:description', content: description });
        // If StoreHeroImages is available and is an array, use the first image
        if (heroSection?.medias && Array.isArray(heroSection?.medias) && heroSection?.medias.length > 0 && heroSection?.medias[0].type === 'IMAGE') {
            const imageUrl = heroSection?.medias[0].preview;
            this.metaService.updateTag({ name: 'twitter:image', content: replaceHostname(imageUrl, this.shopHost) });
        }
        this.logger.debug('Updated metadata for Homepage');
      }

      private removeHomePageMetadata() {
        this.metaService.removeTag('name="description"');
        this.metaService.removeTag('property="og:title"');
        this.metaService.removeTag('property="og:description"');
        this.metaService.removeTag('property="og:type"');
        this.metaService.removeTag('property="og:url"');
        this.metaService.removeTag('property="og:image"');
        this.metaService.removeTag('name="twitter:card"');
        this.metaService.removeTag('name="twitter:title"');
        this.metaService.removeTag('name="twitter:description"');
        this.metaService.removeTag('name="twitter:image"');
    }
}
