export function isStoreRelatedPage(url: string): boolean {
    // Remove any leading '/'
    const normalizedUrl = url.replace(/^\/+/, '');
    const storePagePattern = /^store\/[^\/]+(\/.*)?$/;
    return storePagePattern.test(normalizedUrl);
}

export function normalizeUrlForRouting(baseUrl: string, targetPage: string): string[] {
    const normalizedBaseUrl = baseUrl ? baseUrl.replace(/^\/+|\/+$/g, '') : '';
    const normalizedTargetPage = targetPage.replace(/^\/+|\/+$/g, '');
    let pathSegments = [];
    if (normalizedBaseUrl) {
        pathSegments.push(normalizedBaseUrl);
    }
    pathSegments.push(normalizedTargetPage);
    return pathSegments;
}

export function replaceHostname(urlString: string, newHostUrl: string): string {
    try {
        const url = new URL(urlString);
        const newHost = new URL(newHostUrl).hostname;
        url.hostname = newHost;
        return url.toString();
    } catch (error) {
        console.log('Invalid urlString:', urlString, 'newHostUrl:', newHostUrl);
        return urlString;
    }
}