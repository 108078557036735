<div *ngIf="heroSection && heroSection?.medias?.length > 0" class="hero-banner mb-6" style="min-height: 180px;">
    <vsf-hero-section
        [heroSection]="heroSection"
        [isMobile] = "isMobile">
    </vsf-hero-section>
</div>
<div class="mt-2 mb-2">
    <vsf-product-list
        [channelId]="sellerChannelId">
    </vsf-product-list>
</div>
<div *ngIf="collectionListForShopByGroupList?.length > 0">
    <div *ngFor="let collectionList of collectionListForShopByGroupList" class="mt-2 mb-2">
        <div *ngIf="isMobile" class="mt-8 mb-6">
            <vsf-collection-group-swiper
                [collectionGroup]="collectionList"
                [displayPagination]=true>
            </vsf-collection-group-swiper>
        </div>
        <div *ngIf="(isMobile===false)" class="facet-group mt-8 mb-10">
            <vsf-collection-group-carousel
                [collectionGroup]="collectionList"
                >
            </vsf-collection-group-carousel>
        </div>
    </div>
</div>

