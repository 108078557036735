import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StateService } from "../../../core/providers/state/state.service";
import { takeUntil } from "rxjs/operators";

@Component({
    selector: 'vsf-root-entry-component',
    template: `
      <ng-container *ngIf="(storeIdentifier$ | async); else homeTemplate">
        <vsf-store-main-page></vsf-store-main-page>
      </ng-container>
      <ng-template #homeTemplate>
        <vsf-home-page></vsf-home-page>
      </ng-template>
    `
})
export class RootEntryComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    storeIdentifier$: Observable<string | null>;

    constructor(private stateService: StateService) {}

    ngOnInit() {
        console.log('RootEntryComponent ngOnInit');
        this.storeIdentifier$ = this.stateService.select(state => state.storeIdentifier)
        .pipe(takeUntil(this.destroy$));
        this.storeIdentifier$.subscribe(storeIdentifier => {
          console.log('RootEntryComponent::ngOnInit::storeIdentifier', storeIdentifier);
        });
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }  
}