<div class="newsletter-container" [ngStyle]="{'background-color': backgroundColor}">
    <ng-container *ngIf="type === 'raw'">
      <img [src]="backgroundImage | assetPreview:'full'" alt="Background Image" class="background-image" [style.opacity]="shadowOpacity">
      <div class="newsletter-content">
        <h2>{{title}}</h2>
        <p>{{subtitle}}</p>
        <form (ngSubmit)="onSubmit()">
          <input type="email" placeholder="Enter your email" [(ngModel)]="email" name="email" required>
          <button type="submit">Submit</button>
        </form>
      </div>
    </ng-container>
    <ng-container *ngIf="type === 'omnisend' && omnisendFormReady">
      <div [ngStyle]="{'background-color': backgroundColor}">
        <div [id]="omnisendFormId"></div>
      </div>
    </ng-container>
</div>