import { DOCUMENT, isPlatformBrowser } from '@angular/common';
import { Inject, NgModule, PLATFORM_ID, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { NavigationEnd, Router, RouterModule, UrlSerializer } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LoggerModule } from "ngx-logger";

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app.routes';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { environment } from '../environments/environment';
import { NgxStripeModule } from 'ngx-stripe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CombinedAnalyticsService } from './core/providers/analytics/combined-analytics.service';

import * as Sentry from "@sentry/angular";
import { provideClientHydration } from '@angular/platform-browser';
import { EntryModule } from './entry/entry.module';
import { StoreModule } from './store/store.module';
import { HomeModule } from './home/home.module';
import { ProductModule } from './product/product.module';
import { CollectionModule } from './collection/collection.module';

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserAnimationsModule,
        AppRoutingModule,
        CoreModule,
        SharedModule,
        // Using the service worker appears to break SSR after the initial page load.
        // ServiceWorkerModule.register(`${environment.baseHref}ngsw-worker.js`, {
        //     enabled: environment.production,
        //     registrationStrategy: 'registerWithDelay:5000',
        // }),
        NgxStripeModule.forRoot(environment.stripePublishableKey),
        LoggerModule.forRoot({
            //serverLoggingUrl: '/api/logs',
            level: environment.loggerLevel,
            //serverLogLevel: environment.logger_level,
        }),
    ],
    exports: [
        RouterModule
    ],
    providers: [
        provideClientHydration(),
        CombinedAnalyticsService,
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        // {
        //     provide: 'IS_MOBILE',
        //     useFactory: (platformId: object) => {
        //       // Check if we're in the browser environment
        //       console.log('IS_MOBILE provider');
        //       if (isPlatformBrowser(platformId)) {
        //         const mobileIndicator = document.getElementById('mobile-indicator');
        //         if(mobileIndicator){
        //             return  window.getComputedStyle(mobileIndicator).display !== 'none';
        //         }
        //       }
        //       return false;
        //     },
        //     deps: [PLATFORM_ID],
        //   },
    ],
    bootstrap: [AppComponent],
})

export class AppModule {

    constructor(
        private router: Router,
        private urlSerializer: UrlSerializer,
        @Inject(PLATFORM_ID) private platformId: object,
        @Inject(DOCUMENT) private document?: Document,
    ) {
        if (isPlatformBrowser(this.platformId)) {
            this.handleScrollOnNavigations();
        }
    }

    /**
     * A work-around for undesirable scoll behaviour caused by the router's `scrollPositionRestoration` setting.
     * When set to 'enabled', it correctly handles scrolling to the top on navigation, and preserving scroll position
     * on "back" navigation. However, it _also_ causes the page to scroll to the top when changing search facet value filters,
     * which is very undesirable. Since there seems to be currently no way to disable the scrolling on a per-navigation basis,
     * we are manually implementing scroll-to-top-on-nav and adding an exception for when the "facets" param of the "gp"
     * routes change.
     */
    private handleScrollOnNavigations() {
        this.router.events.pipe(
            filter((e): e is NavigationEnd => e instanceof NavigationEnd),
        ).subscribe(event => {
            if (this.document?.defaultView) {
                const parsed = this.urlSerializer.parse(event.urlAfterRedirects);
                const primaryRoot = parsed.root.children.primary;
                const isFacetFilterNavigation = (primaryRoot?.segments[0]?.path === 'gp' &&
                    primaryRoot?.segments[1]?.parameterMap.has('facets'));

                if (!isFacetFilterNavigation) {
                    this.document.defaultView.scrollTo({
                        top: 0,
                    });
                }
            }
        });
    }
}
