import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { NGXLogger } from 'ngx-logger';
import { Asset, GetStoreInfoQuery } from '../../../common/generated-types';
import { take } from 'rxjs/operators';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { ColorConfig, FaqPageData, QAData } from '../../../common/interfaces';
import { BehaviorSubject, combineLatest } from 'rxjs';
import { StateService } from '../../providers/state/state.service';
import { CanonicalService } from '../../providers/canonical/canonical.service';

@Component({
    selector: 'vsf-faq-page',
    templateUrl: './faq-page.component.html',
    styleUrls: ['./faq-page.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FaqPageComponent implements OnInit, OnDestroy {
    identifier: string;
    storeInfo: GetStoreInfoQuery['storeSite'];
    faqPage: FaqPageData | null;
    colorConfig: ColorConfig | null;
    posterAsset: Asset;
    title: string;
    faqList: QAData[];
    showAll = false;
    defaultDisplayCount = 3;
    isMobile: boolean | null = null;
    readyToDisplay$ = new BehaviorSubject<boolean>(false);

    constructor(private logger: NGXLogger,
                private canonicalService: CanonicalService,
                private stateService: StateService) { }

    ngOnInit(): void {
        const isMobileSub = this.stateService.select(state => state.isMobile);
        const storeInfoSub = this.stateService.select(state => state.storeInfo);
        const storePathSub = this.stateService.select(state => state.storePath);
        const shopHostSub = this.stateService.select(state => state.shopHost);
        combineLatest([isMobileSub, storeInfoSub, storePathSub, shopHostSub]).pipe(take(1)).subscribe(([isMobile, storeInfo, storePath, shopHost]) => {
            this.isMobile = isMobile;
            this.storeInfo = storeInfo;
            this.identifier = storeInfo?.identifier || '';
            this.colorConfig = safeJSONParse<ColorConfig>(this.storeInfo?.colorConfig, this.logger);
            this.faqPage = this.storeInfo?.faqPageData as FaqPageData;
            if(this.faqPage) {
                this.logger.info('[FAQ page] content:', this.faqPage);
                this.posterAsset = this.faqPage.posterAsset || this.posterAsset;
                this.title = this.faqPage.title || this.title;
                this.faqList = this.faqPage.qaList.map(item => {
                    return {
                        question: item?.question,
                        answer: item?.answer,
                        open: false,
                    };
                });
                if(this.faqList.length >= this.defaultDisplayCount) {
                    this.showAll = false;
                }
            }
            const canonicalURL = `${storePath}/faq`;
            this.canonicalService.setCanonicalURL(`${shopHost}${canonicalURL}`);
            this.readyToDisplay$.next(true);
        });
    }

    ngOnDestroy() {
        this.canonicalService.removeCanonicalURL();
    }

    toggleFaq(faq: QAData): void {
        faq.open = !faq.open;
    }

    getVisibleFaqs(): QAData[] {
        return this.showAll ? this.faqList : this.faqList.slice(0, this.defaultDisplayCount);
    }

    showAllQuestions(): void {
        this.showAll = true;
    }
}
