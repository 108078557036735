import { ChangeDetectorRef, Pipe, PipeTransform } from '@angular/core';
import { take } from 'rxjs/operators';
import { StateService } from '../../core/providers/state/state.service';

/**
 * A pipe which formats a price (always given as an integer by Venure) according
 * to the currencyCode of the current Channel.
 */
@Pipe({
    name: 'formatPrice',
    pure: false,
})
export class FormatPricePipe implements PipeTransform {

    private latestValue: any = null;
    private latestReturnedValue: any = null;
    private static readonly DEFAULT_CHANNEL_DATA = {
        defaultLanguageCode: 'en',
        currencyCode: 'USD',
    };

    constructor(
        private changeDetector: ChangeDetectorRef,
        private stateService: StateService) {}

    transform(value: number) {
        if (this.latestValue !== value) {
            this.latestValue = value;
            this.formatCurrency(value);
        }
        return this.latestReturnedValue;
    }

    private formatCurrency(value: number) {
        this.stateService.select(state => state.storeInfo).pipe(take(1))
        .subscribe(storeInfo => {
            const channel = storeInfo?.sellerChannel || FormatPricePipe.DEFAULT_CHANNEL_DATA;
            const formatter = Intl.NumberFormat(channel.defaultLanguageCode, {
                style: 'currency',
                currency: channel.currencyCode,
            });
            this.latestReturnedValue = formatter.format(value / 100);
            this.changeDetector.markForCheck();
        });
    }
}
