import { Component, OnInit, ElementRef, Renderer2, Input, ViewChild, ChangeDetectorRef, AfterViewInit, OnDestroy, PLATFORM_ID, Inject } from '@angular/core';
import { DataService } from '../../providers/data/data.service';
import { SearchProductsQuery, SearchProductsQueryVariables, SearchVariantsQuery, SearchVariantsQueryVariables } from '../../../common/generated-types';
import { Observable, Subject, Subscription } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';
import { SEARCH_PRODUCTS, SEARCH_VARIANTS } from '../../../common/graphql/documents.graphql';
import { SwiperContainer } from 'swiper/element';
import { CUSTOM_ELEMENTS_SCHEMA  } from '@angular/core';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { CollectionItemOrderData } from '../../../common/interfaces';
import { CollectionItemType } from '../../../common/enums';
import { notNullOrUndefined } from '../../../common/utils/not-null-or-undefined';
import { NGXLogger } from 'ngx-logger';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'vsf-product-swiper',
  templateUrl: './product-swiper.component.html',
  styleUrls: ['./product-swiper.component.scss']
})
export class ProductSwiperComponent implements OnInit, AfterViewInit, OnDestroy {
  private destroy$: Subject<void> = new Subject<void>();
  products$: any;
  displayedItems: SearchProductsQuery['search']['items'] | SearchVariantsQuery['search']['items'] = [] ;
  itemIdsOrder: string[] = [];
  selectedIndex = 1;
  totalSlides = 0;
  gridRows = 1;
  isBrowser: boolean;

  @Input() collectionSlug: string;
  @Input() collectionItemOrder: string;
  @Input() displayType: CollectionItemType = CollectionItemType.Product;
  @Input() headerTitle: string;
  @Input() channelId: string;
  @Input() layoutMode: string;
  @Input() swipeDirectoin: string = 'horizontal';
  @Input() itemsPerRow: number;
  @Input() itemsPerSlide: number;
  @Input() displayBuyButton: boolean = false;
  @Input() displayPrice: boolean = false;
  @Input() displaySubtitle: boolean = false;
  @Input() displayPagination: boolean = false;
  @Input() imgSize: 'sm-h' | 'sm-v' | 'md' | 'lg' = 'md';

  @ViewChild('swiper') swiper!: ElementRef<SwiperContainer>;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private dataService: DataService,
    private chageDetector: ChangeDetectorRef,
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private logger: NGXLogger,
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnInit(): void {
    this.logger.debug('ProductSwiperComponent Initializing', this.collectionSlug, this.displayType, this.collectionItemOrder);
    if (this.collectionItemOrder) {
      const collectionItemOrderData = safeJSONParse<CollectionItemOrderData>(this.collectionItemOrder, this.logger);
      this.itemIdsOrder = (this.displayType === CollectionItemType.Product) ? (collectionItemOrderData?.productIdsInOrder || []) : (collectionItemOrderData?.variantIdsInOrder || []);
    }
    this.gridRows = this.itemsPerSlide / this.itemsPerRow;

    this.products$ = this.createItemQuery(this.displayType);
    this.products$.subscribe((items:any) => {
      this.displayedItems = items;
      this.totalSlides = Math.ceil(this.displayedItems.length / this.itemsPerSlide);
      const totalItems = this.displayedItems.length;
      if (totalItems <= 6) {
        this.gridRows = Math.min(3, Math.floor(this.itemsPerSlide / this.itemsPerRow));
      } else {
        let calculatedRows = Math.floor(this.itemsPerSlide / this.itemsPerRow);
        if (calculatedRows % 2 !== 0) {
          calculatedRows += 1; // Make it even
        }
        this.gridRows = calculatedRows;
      }
      this.chageDetector.detectChanges();
    });
  }

  ngAfterViewInit() {
    if(this.isBrowser && this.swiper.nativeElement.swiper) {
      this.swiper.nativeElement.swiper.onAny((eventName: string) => this.slideEvent(eventName));
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private createItemQuery<T extends CollectionItemType>(queryType: T) {
    const input = {
      take: 100,
      groupByProduct: queryType === CollectionItemType.Product,
      channelId: this.channelId,
      collectionSlug: this.collectionSlug || '',
    };
    const query = queryType === CollectionItemType.Product ? SEARCH_PRODUCTS : SEARCH_VARIANTS;
    return this.dataService.query<
      SearchProductsQuery | SearchVariantsQuery,
      SearchProductsQueryVariables|SearchVariantsQueryVariables
    >(query, {
      input,
    }).pipe(
      map((data: any) => {
        const items = data.search.items;
        if (this.itemIdsOrder?.length > 0) {
          return this.itemIdsOrder
            .map(id => items.find((item: any) => queryType === CollectionItemType.Product ? item?.productId === id : item?.productVariantId === id))
            .filter(notNullOrUndefined);
        }
        return items;
      }),
      takeUntil(this.destroy$)
    );
  }

  slideEvent(eventName: string) {
    if(eventName === 'slideChange') {
      this.selectedIndex = Math.ceil(this.swiper.nativeElement.swiper.activeIndex / this.itemsPerRow) + 1;
    }
  }

  swipeToNext() {
    if(!this.swiper.nativeElement.swiper.isEnd) {
      this.swiper.nativeElement.swiper.slideNext();
    }
  }

  swipeToPrev() {
    if(!this.swiper.nativeElement.swiper.isBeginning) {
      this.swiper.nativeElement.swiper.slidePrev();
    }
  }

  trackByProductId(index: number, item: SearchProductsQuery['search']['items'][number] | SearchVariantsQuery['search']['items'][number]): string {
    if(this.displayType === CollectionItemType.Product) {
      return item.productId;
    } else {
      return item.productVariantId;
    }
  }
}