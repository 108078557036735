// combined-analytics.service.ts

import { Injectable } from '@angular/core';
import { IAnalyticsService } from './analytics.interface';
import { AnalyticsService } from './analytics.service';
import { OmniSendService } from './omnisend.service';
import { Order, Product, ProductVariant } from '../../../common/generated-types';
import { StateService } from '../state/state.service';


@Injectable({
    providedIn: 'root'
})
export class CombinedAnalyticsService implements IAnalyticsService {
    constructor(
        private gaService: AnalyticsService,
        private omniSendService: OmniSendService,
        private stateService: StateService,
    ) {
        this.initialize();
    }

    public initialize(): void {
        this.stateService.select(state => state.shopHost).subscribe(shopHost => {
            this.omniSendService.loadOmnisend(shopHost);
        });
        this.gaService.initialize();
    }

    public setEmail(email: string): void {
        this.gaService.setEmail(email);
        this.omniSendService.setEmail(email);
    }

    public viewItem(product: Product): void {
        this.gaService.viewItem(product);
        this.omniSendService.viewItem(product);
    }

    public selectItem(product: Product): void {
        this.gaService.selectItem(product);
    }

    public addToCart(product: Product, variant: ProductVariant, quantity: number, order?: Order): void {
        this.gaService.addToCart(product, variant, quantity);
        if (order) {
            this.omniSendService.addToCart(product, variant, quantity, order);
        }
    }

    public beginCheckout(order: Order, callback?: any): void {
        this.omniSendService.beginCheckout(order);
        this.gaService.beginCheckout(order, callback);
    }

    public addShippingInfo(order: Order, callback?: any): void {
        this.omniSendService.addShippingInfo(order);
        this.gaService.addShippingInfo(order, callback);
    }

    public purchase(order: Order, callback?: any): void {
        this.omniSendService.purchase(order);
        this.gaService.purchase(order, callback);
    }
}
