import { ChangeDetectionStrategy, Component, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID, OnDestroy, Input } from '@angular/core';
import { distinctUntilChanged, take, takeUntil } from 'rxjs/operators';

import { Customer, GetActiveCustomerQuery } from '../../../common/generated-types';
import { GET_ACTIVE_CUSTOMER } from '../../../common/graphql/documents.graphql';
import { DataService } from '../../providers/data/data.service';
import { StateService } from '../../providers/state/state.service';
import { retry } from 'ts-retry-promise';
import { isPlatformBrowser } from '@angular/common';
import { Subject } from 'rxjs';
import { NGXLogger } from 'ngx-logger';
import { CombinedAnalyticsService } from '../../providers/analytics/combined-analytics.service';
import { FetchPolicy } from '@apollo/client';

@Component({
    selector: 'vsf-account-button',
    templateUrl: './account-button.component.html',
    styleUrls: ['./account-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountButtonComponent implements OnInit, OnDestroy {
    baseUrl: string = '';
    signedIn = false;
    isQueryValid = false;
    activeCustomer: Customer | null;
    private static readonly MAX_QUERY_RETRIES = 15;
    private static readonly QUERY_INTERVAL = 200;
    private destroy$: Subject<void> = new Subject<void>();

    constructor(@Inject(PLATFORM_ID) private platformId: object,
                private dataService: DataService,
                private stateService: StateService,
                private changeDetector: ChangeDetectorRef,
                private analyticsService: CombinedAnalyticsService,
                private logger: NGXLogger,
            ) {}

    ngOnInit() {
        this.stateService.select(state => state.storePath).pipe(take(1), takeUntil(this.destroy$)).subscribe(storePath => {
            this.baseUrl = storePath;
        });
        this.stateService.select(state => state.signedIn).pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((signedIn) => {
            this.logger.debug(`[${new Date().toISOString()}][account-button.component] Signed in state: ${signedIn}`);
            this.signedIn = signedIn;
            this.changeDetector.markForCheck();
            this.fetchActiveCustomer();
        });
        this.stateService.select(state => state.clientInitState).pipe(distinctUntilChanged(), takeUntil(this.destroy$))
        .subscribe((clientInitState) => {
            if (isPlatformBrowser(this.platformId) && !clientInitState) {
                this.fetchActiveCustomerWithRetry().then(() => {
                    this.stateService.setState('clientInitState', true);
                });
            }
        });
    }

    ngOnDestroy() {
        this.destroy$.next();
        this.destroy$.complete();
    }

    private fetchActiveCustomer(type = 'cache-and-network') {
        this.dataService.query<GetActiveCustomerQuery>(GET_ACTIVE_CUSTOMER, {}, type as FetchPolicy)
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(data => {
            if(data?.activeCustomer) {
                this.stateService.setState('signedIn', true);
                this.signedIn = true;
                this.activeCustomer = data?.activeCustomer as Customer;
                if(this.activeCustomer?.emailAddress) {
                    this.analyticsService.setEmail(this.activeCustomer.emailAddress);
                }
                this.changeDetector.markForCheck();
                this.logger.debug(`[${new Date().toISOString()}][account-button.component] fetched activeCustomer.`);
            }
            this.isQueryValid = true;
        });
    }

    async fetchActiveCustomerWithRetry() {
        try {
            await retry(
                async() => this.fetchActiveCustomer('network-only'),
                {
                    retries: AccountButtonComponent.MAX_QUERY_RETRIES,
                    delay: AccountButtonComponent.QUERY_INTERVAL,
                    until: () => this.isQueryValid,
                }
            );
        } catch (error) {
            this.logger.error(`[${new Date().toISOString()}][account-button.component] Fetching after max retries.`);
        }
    }
}
