import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, OnInit, PLATFORM_ID  } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { safeJSONParse } from '../../../common/utils/safe-json-parser';
import { Address } from '../../../common/generated-types';

@Component({
    selector: 'vsf-return-request-rma-form',
    templateUrl: './return-request-rma-form.component.html',
    styleUrls: ['./return-request-rma-form.component.scss'],
})

export class ReturnRequestRmaFormComponent implements OnInit, AfterViewInit {
    formData: any;
    sellerAddress: Address;
    isPrintable = false;

    constructor(
        private route: ActivatedRoute,
        @Inject(PLATFORM_ID) private platformId: object,
        private logger: NGXLogger
    ) {}

    ngOnInit() {
        const encodedFormData = this.route.snapshot.queryParams['info'];
        if (encodedFormData) {
            const decodedFormData = atob(encodedFormData);
            this.formData = safeJSONParse(decodedFormData, this.logger);
            const sellerAddress = safeJSONParse<Address>(this.formData?.sellerAddressTo, this.logger);
            if(sellerAddress) {
                this.sellerAddress = sellerAddress;
            }
        }
    }

    ngAfterViewInit() {
        this.isPrintable = true;
      }

    close() {
        if (isPlatformBrowser(this.platformId)) {
            window.close();
        }
    }

    printForm() {
        if (isPlatformBrowser(this.platformId) && this.isPrintable) {
            import('print-js').then((printJS) => {
              printJS.default({
                printable: 'return-form',
                type: 'html',
                targetStyles: ['*'],
              });
            });
        }
    }
}
