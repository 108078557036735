import { Component, OnDestroy, OnInit } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { StateService } from "../../../core/providers/state/state.service";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: 'vsf-search-entry-component',
  template: `
    <ng-container *ngIf="(storeIdentifier$ | async); else productListTemplate">
      <vsf-store-shopping-page></vsf-store-shopping-page>
    </ng-container>
    <ng-template #productListTemplate>
      <vsf-product-list></vsf-product-list>
    </ng-template>
  `
})
export class SearchEntryComponent implements OnInit, OnDestroy {
    private destroy$ = new Subject<void>();
    storeIdentifier$: Observable<string | null>;

    constructor(private stateService: StateService) {}

    ngOnInit() {
      console.log('SearchEntryComponent ngOnInit');
      this.storeIdentifier$ = this.stateService.select(state => state.storeIdentifier)
      .pipe(takeUntil(this.destroy$));
      this.storeIdentifier$.subscribe(storeIdentifier => {
        console.log('SearchEntryComponent::ngOnInit::storeIdentifier', storeIdentifier);
      });
    }

    ngOnDestroy(): void {
      this.destroy$.next();
      this.destroy$.complete();
    }  
}