import { Component, Input, OnInit, OnDestroy, ElementRef, ChangeDetectionStrategy, AfterViewInit, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import Player from '@vimeo/player';
import { fromEvent, Subscription } from 'rxjs';
import { bufferTime, filter, map } from 'rxjs/operators';
import { StateService } from '../../../core/providers/state/state.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

@Component({
    selector: 'vsf-vimeo-player',
    templateUrl: './vimeo-player.component.html',
    styleUrls: ['./vimeo-player.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VimeoPlayerComponent implements OnInit, AfterViewInit, OnDestroy {
    @Input() source = '';
    @Input() width = 0;
    @Input() height = 0;

    isMuted = true;
    vimeoElementId: string;
    videoId: number;

    private vimeoPlayer: Player;
    private scrollSubscription: Subscription;
    private static readonly SCROLL_SUBSCRIPTION_BUFFER_TIME = 250;

    constructor(
        private changeDetector: ChangeDetectorRef,
        private elementRef: ElementRef,
        @Inject(PLATFORM_ID) private platformId: object,
        private stateService: StateService) {
    }

    ngOnInit () {
        this.videoId = this.getVimeoId(this.source);
        this.vimeoElementId = `vimeo-video-${this.videoId}-${Date.now()}`;
    }

    ngAfterViewInit () {
        // Delay the initialization of the Vimeo player
        setTimeout(() => {
            this.initVimeoPlayer();
            this.initScrollListener();
        });
    }

    ngOnDestroy () {
        if (this.vimeoPlayer) {
            this.vimeoPlayer.destroy();
        }
        if (this.scrollSubscription) {
            this.scrollSubscription.unsubscribe();
        }
    }

    private initVimeoPlayer () {
        if(isPlatformServer(this.platformId)) {
            return;
        }
        if (!this.videoId) {
            console.error('Vimeo video ID is not valid');
            return;
        }
        const options: Player.Options = {
            id: this.videoId,
            controls: false,
            muted: true,
            loop: true,
            autoplay: true,
        };
        if (this.width > 0) {
            options.width = this.width;
        }
        if (this.height > 0) {
            options.height = this.height;
        }
        this.vimeoPlayer = new Player(this.vimeoElementId, options);

        this.vimeoPlayer.ready().then(() => {
            const iframe = this.elementRef.nativeElement.querySelector('iframe');
            if (iframe) {
                iframe.style.pointerEvents = 'none';
            }
            
        });
        this.vimeoPlayer.play();
    }
    
    onPlayerClick (event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        // this.toggle.emit(this.videoId);
        this.stateService.setState('fullscreenPreviewOpen', this.videoId);
    }

    onPlayerKeydown (event: KeyboardEvent) {
        event.stopPropagation();
        event.preventDefault();
    }

    async toggleMute (event: MouseEvent) {
        event.stopPropagation();
        event.preventDefault();
        // fix : Unmuting failed and the element was paused instead because the user didn't interact with the document before.
        try {
            // use setVolume instead of setMuted to avoid the error
            // await this.vimeoPlayer.setMuted(!this.isMuted);
            await this.vimeoPlayer.setVolume(this.isMuted ? 1 : 0);
            this.isMuted = !this.isMuted;
            // trigger change detection
            this.changeDetector.detectChanges();
        } catch (error) {
            console.error('Error toggling mute', error);
            setTimeout(() => {
                this.vimeoPlayer.play();
            this.toggleMute(event);
            });
        }
    }

    getVimeoId (source: string): number {
        const match = source.match(/vimeo\.com\/(\d+)/);
        return match ? parseInt(match[1], 10) : 0;
    }

    isElementInViewport (el: HTMLElement) {
        if(isPlatformBrowser(this.platformId)) {
            const rect = el.getBoundingClientRect();
            return (
                rect.top >= 0 &&
                rect.left >= 0 &&
                rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
                rect.right <= (window.innerWidth || document.documentElement.clientWidth)
            );
        }
    }

    private initScrollListener() {
        if(isPlatformBrowser(this.platformId)) {
            // Wait for DOM to be ready
            setTimeout(() => {
                this.scrollSubscription = fromEvent(window, 'scroll', { passive: true }).pipe(
                    map(() => window.scrollY),
                    bufferTime(VimeoPlayerComponent.SCROLL_SUBSCRIPTION_BUFFER_TIME),
                    filter(val => 1 < val.length),
                    map(val => ({
                        delta: val[val.length - 1] - val[0],
                        currentScroll: val[val.length - 1]
                    }))
                ).subscribe(async () => {
                    if (this.vimeoPlayer) {
                        if (this.isElementInViewport(this.elementRef.nativeElement)) {
                            if (await this.vimeoPlayer.getPaused()) {
                                this.vimeoPlayer.play();
                            }
                        } else {
                            if (!await this.vimeoPlayer.getPaused()) {
                                this.vimeoPlayer.pause();
                            }
                    }
                    }
                });
            }, 0);
        }
    }
}
