import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SafeStyle } from '@angular/platform-browser';

import { Collection, GetCollectionQuery } from '../../../common/generated-types';
import { Router } from '@angular/router';
import { ColorConfig } from '../../../common/interfaces';
import { StateService } from '../../../core/providers/state/state.service';

@Component({
    selector: 'vsf-two-collection-card-v2',
    templateUrl: './two-collection-card-v2.component.html',
    styleUrls: ['./two-collection-card-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TwoCollectionCardComponentV2 implements OnInit {
    @Input() collectionA: NonNullable<GetCollectionQuery['collection']>;
    @Input() collectionB: NonNullable<GetCollectionQuery['collection']>;
    @Input() colorConfig: ColorConfig | null;

    backgroundImage: SafeStyle;
    showOverlay: boolean = false;
    collectionBaseUrl = '';

    constructor(
        private router: Router,
        private stateService: StateService,
    ) {}

    ngOnInit() {
        this.stateService.select(state => state.storePath).subscribe(storePath => {
            this.collectionBaseUrl = `${storePath}/gp`;
        });
    }
    navigateToCollectionPage(collection: Collection) {
        this.router.navigate([this.collectionBaseUrl, collection.slug]);
    }
}
