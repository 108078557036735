<div *ngIf="storeInfo && (readyToDisplay$ | async)">
    <div *ngIf="collectionForSeriesList?.length > 0 && isShoppingRoute">
        <div *ngIf="isMobile">
            <vsf-dual-collections-swiper
                [collectionsList]="collectionForSeriesList"
                [colorConfig]="colorConfig">
            </vsf-dual-collections-swiper>
        </div>
        <div *ngIf="!isMobile" class="mt-4 mb-6">
            <vsf-collections-carousel
                [collectionsList]="collectionForSeriesList"
                [colorConfig]="colorConfig">
            </vsf-collections-carousel>
        </div>
    </div>
    <div *ngIf="storeInfo.identifier && storeInfo.sellerChannelId" class="mt-6 mb-4">
        <vsf-product-list
            [channelId]="storeInfo.sellerChannelId">
        </vsf-product-list>
    </div>
    <div *ngIf="collectionListForShopByGroupList?.length > 0">
        <div *ngFor="let collectionList of collectionListForShopByGroupList">
            <div *ngIf="isMobile" class="mt-8 mb-6">
                <vsf-collection-group-swiper
                    [collectionGroup]="collectionList"
                    [displayPagination]=true>
                </vsf-collection-group-swiper>
            </div>
            <div *ngIf="!isMobile" class="facet-group mt-8 mb-10">
                <vsf-collection-group-carousel
                    [collectionGroup]="collectionList"
                    >
                </vsf-collection-group-carousel>
            </div>
        </div>
    </div>    
</div>
