<div class="collection-carousel">
  <div *ngIf="groupName" class="group-name flex flex-col items-center">
    <p>Shop by {{ groupName }}</p>
    <div class="title-underline my-2"></div>
  </div>
  <div class="w-100 ml-1 mr-1 min-h-[300px]">

    <!-- If using Swiper is determined and this is the browser, show Swiper; otherwise show simple or fallback list -->
    <ng-container *ngIf="useSwiper; else noSwiperList">
      <ng-container *ngIf="isBrowser; else fallbackList">
        <swiper-container
          appSwiper
          #swiper
          [slidesPerView]="slidesPerView"
          [pagination]="{ clickable: true }"
          spaceBetween="30"
          [centeredSlides]="false"
          [rewind]="true"
          class="flex object-cover"
        >
          <swiper-slide
            *ngFor="let collection of displayedCollections; trackBy: trackByCollectionId"
          >
            <div class="slide-item">
              <vsf-collection-card-v2
                [collection]="collection"
                [isMobile]="true"
                class="card"
              ></vsf-collection-card-v2>
            </div>
          </swiper-slide>
        </swiper-container>
      </ng-container>
    </ng-container>

    <!-- If useSwiper is false, show simple list -->
    <ng-template #noSwiperList>
      <div class="flex flex-wrap justify-center items-center mx-auto">
        <div *ngFor="let collection of displayedCollections" class="w-auto m-2 flex-shrink-0">
          <vsf-collection-card-v2
            [collection]="collection"
            [isMobile]="true"
          ></vsf-collection-card-v2>
        </div>
      </div>
    </ng-template>

    <!-- Fallback list template for SSR -->
    <ng-template #fallbackList>
      <!-- This fallback list ensures that on SSR we don't show an empty space -->
      <div class="flex flex-wrap justify-center items-center mx-auto">
        <div *ngFor="let collection of displayedCollections" class="w-auto m-2 flex-shrink-0">
          <vsf-collection-card-v2
            [collection]="collection"
            [isMobile]="true"
          ></vsf-collection-card-v2>
        </div>
      </div>
    </ng-template>
  </div>
</div>
