import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer, SafeStyle } from '@angular/platform-browser';

import { GetCollectionQuery, GetCollectionQueryVariables } from '../../../common/generated-types';
import { AssetPreviewPipe } from '../../pipes/asset-preview.pipe';
import { DataService } from '../../../core/providers/data/data.service';
import { GET_COLLECTION } from '../../../common/graphql/documents.graphql';
import { stripHtml } from '../../../common/utils/strip-html';
import { NGXLogger } from 'ngx-logger';
import { StateService } from '../../../core/providers/state/state.service';

@Component({
    selector: 'vsf-collection-card-v2',
    templateUrl: './collection-card-v2.component.html',
    styleUrls: ['./collection-card-v2.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CollectionCardV2Component implements OnInit, OnChanges {
    @Input() collection: NonNullable<GetCollectionQuery['collection']>;
    @Input() collectionId: string;
    @Input() isMobile: boolean = false;
    backgroundImage: SafeStyle;
    showOverlay: boolean = false;
    stripHtml: Function = stripHtml;
    collectionBaseUrl = '';
    static readonly CARD_IMAGE_WIDTH = 200;
    static readonly CARD_IMAGE_HEIGHT = 500;

    constructor(private sanitizer: DomSanitizer,
                private dataService: DataService,
                private stateService: StateService,
                private changeDetector: ChangeDetectorRef,
                private logger: NGXLogger,
    ) {}

    ngOnInit() {
        if(!this.collection && this.collectionId) {
            this.dataService.query<GetCollectionQuery, GetCollectionQueryVariables>(GET_COLLECTION, {
                id: this.collectionId,
            }).subscribe((result:any) => {
                this.collection = result.collection;
                this.changeDetector.markForCheck();
                this.logger.debug('[CollectionCardComponent] collection:', this.collection);
            });
        } else {
            this.logger.debug('[CollectionCardComponent] collection:', this.collection);
        }
        this.stateService.select(state => state.storePath).subscribe(storePath => {
            this.collectionBaseUrl = `${storePath}/gp`;
        });
    }

    ngOnChanges() {
        if (this.collection.featuredAsset) {
            const assetPreviewPipe = new AssetPreviewPipe();
            this.backgroundImage = this.sanitizer.bypassSecurityTrustStyle(
                `url('${assetPreviewPipe.transform(
                        this.collection.featuredAsset,
                        CollectionCardV2Component.CARD_IMAGE_WIDTH,
                        CollectionCardV2Component.CARD_IMAGE_HEIGHT)}'
                    )`,
            );
        }
    }
}
