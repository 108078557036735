import { isPlatformBrowser } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { FactoryProvider, Optional, PLATFORM_ID } from '@angular/core';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { RetryLink } from "@apollo/client/link/retry";
import { REQUEST } from '@nguniversal/express-engine/tokens';
import { APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink, Options } from 'apollo-angular/http';
import { Request } from 'express';
import { NGXLogger } from 'ngx-logger';

import { environment } from '../../environments/environment';
import possibleTypesResult from '../common/introspection-results';

const STATE_KEY = makeStateKey<any>('apollo.state');
let apolloCache: InMemoryCache;

export const APOLLO_CLIENT_PROVIDER: FactoryProvider = {
    provide: APOLLO_OPTIONS,
    useFactory: apolloOptionsFactory,
    deps: [HttpLink, PLATFORM_ID, TransferState, NGXLogger, [new Optional(), REQUEST]],
};

function mergeFields(existing: any, incoming: any) {
    return { ...existing, ...incoming };
}

function relaceFields(existing: any, incoming: any) {
    return incoming;
}

// Trying to debug why sessions won't work in Safari 13.1
// but only on the live prod version.
function logInterceptorData(on: boolean) {
    localStorage.setItem('_logInterceptorData', on ? 'true' : 'false');
}

if (typeof window !== 'undefined') {
    (window as any).logInterceptorData = logInterceptorData;
}

export function constructUri(apiHost: string, apiPort: number, shopApiPath: string): string {
    // Check if the port is standard (443 for HTTPS, 80 for HTTP) and skip adding it if so
    const isStandardPort =
        (apiPort === 443 && apiHost.startsWith("https")) ||
        (apiPort === 80 && apiHost.startsWith("http"));

    return isStandardPort
        ? `${apiHost}/${shopApiPath}` // Exclude the port for standard ports
        : `${apiHost}:${apiPort}/${shopApiPath}`; // Include the port otherwise
}


export function apolloOptionsFactory(
    httpLink: HttpLink,
    platformId: object,
    transferState: TransferState,
    logger: NGXLogger,
    req?: Request,
): ApolloClientOptions<any> {
    const AUTH_TOKEN_KEY = 'auth_token';
    const CHANNEL_TOKEN = 'channel_token';
    const MAX_REQUEST_RETRIES = 3;
    const INITIAL_RETRY_INTERVAL = 200;
    const RETRY_INTERVAL_POWER_BASE = 2;
    apolloCache = new InMemoryCache({
        possibleTypes: possibleTypesResult.possibleTypes,
        typePolicies: {
            Query: {
                fields: {
                    eligibleShippingMethods: {
                        merge: relaceFields,
                    },
                },
            },
            Product: {
                fields: {
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
            Collection: {
                fields: {
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
            Order: {
                fields: {
                    lines: {
                        merge: relaceFields,
                    },
                    shippingLines: {
                        merge: relaceFields,
                    },
                    discounts: {
                        merge: relaceFields,
                    },
                    shippingAddress: {
                        merge: relaceFields,
                    },
                    billingAddress: {
                        merge: relaceFields,
                    },
                },
            },
            Customer: {
                fields: {
                    addresses: {
                        merge: relaceFields,
                    },
                    customFields: {
                        merge: mergeFields,
                    },
                },
            },
        },
    });

    const { apiHost, apiPort, shopApiPath } = environment;
    const uri = constructUri(apiHost,apiPort,shopApiPath);
    const options: Options = {
        uri,
        withCredentials: false,
    };

    const http = httpLink.create(options);
    const retry = new RetryLink({
        attempts: {
            max: MAX_REQUEST_RETRIES,
            retryIf: (error, _operation) => !!error
        },
        delay: (count, operation, error) => {
          const delay = (RETRY_INTERVAL_POWER_BASE ** count) * INITIAL_RETRY_INTERVAL;
          if (count > 0) { // Don't log on the first attempt (count starts at 0)
            logger.warn(`[RetryLink] Retrying operation '${operation.operationName}' (attempt ${count}) after delay of ${delay.toFixed(2)}ms due to error:`, JSON.stringify(error.message));
          }
          return delay;
        },
    });

    const afterware = new ApolloLink((operation, forward) => {
        return forward(operation).map((response) => {
            const context = operation.getContext();
            const authHeader = context.response.headers.get('vendure-auth-token');
            if (authHeader && isPlatformBrowser(platformId) && typeof localStorage !== 'undefined') {
                // If the auth token has been returned by the Vendure
                // server, we store it in localStorage
                localStorage.setItem(AUTH_TOKEN_KEY, authHeader);
            }
            logger.info('Apollo afterware response:', response);
            return response;
        });
    });
    const middleware = new ApolloLink((operation, forward) => {
        let headers = new HttpHeaders();

        if (isPlatformBrowser(platformId) && typeof localStorage !== 'undefined') {
            headers = headers.set(
                'Authorization',
                `Bearer ${localStorage.getItem(AUTH_TOKEN_KEY) || null}`,
            );
        }

        if(isPlatformBrowser(platformId)){
            const channelToken = localStorage.getItem(CHANNEL_TOKEN) || null;
            if (channelToken && typeof channelToken === 'string' && channelToken.length > 0) {
                headers = headers.set(
                    'vendure-token',
                    channelToken
                );
            }
        }
        operation.setContext({ headers });
        logger.info('Apollo middleware request:', operation);
        return forward(operation);
    });

    const isBrowser = transferState.hasKey<any>(STATE_KEY);

    if (isBrowser) {
        const state = transferState.get<any>(STATE_KEY, null);
        apolloCache.restore(state);
    } else {
        transferState.onSerialize(STATE_KEY, () => {
            return apolloCache.extract();
        });
        // Reset apolloCache after extraction to avoid sharing between requests
        apolloCache.reset();
    }

    return {
        cache: apolloCache,
        ssrMode: false,
        ssrForceFetchDelay: 0,
        link: ApolloLink.from([middleware, afterware, retry, http]),
    };
}
