import {
  AfterViewInit,
  ChangeDetectorRef,
  Component, ElementRef,
  HostListener, Inject,
  Input, OnChanges, OnInit,
  PLATFORM_ID, ViewChild
} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import { SwiperContainer } from 'swiper/element';
import { NGXLogger } from 'ngx-logger';
import { AssetFragment } from '../../../common/generated-types';
import Swiper from 'swiper';
import { StateService } from '../../../core/providers/state/state.service';
import { take } from 'rxjs/operators';

export type AssetWithDimensions = Pick<AssetFragment, 'id' | 'preview' | 'width' | 'height' | 'type' | 'mimeType'>;

@Component({
  selector: 'vsf-asset-swiper-gallery-v2',
  templateUrl: './asset-swiper-gallery-v2.component.html',
  styleUrls: ['./asset-swiper-gallery-v2.component.scss'],
})
export class AssetSwiperGalleryV2Component implements OnInit, OnChanges, AfterViewInit {
  @Input() assets?: AssetWithDimensions[] = [];
  @Input() selectedAssetId: string;
  @ViewChild('mainPreview', { static: false }) featuredAssetLoaded = false;
  private mainPreview: ElementRef<HTMLImageElement>;
  @ViewChild('desktopSwiper') swiper!: ElementRef<SwiperContainer>;
  @ViewChild('mobileSwiper') mobileSwiper!: ElementRef<SwiperContainer>;
  selectedAsset?: AssetWithDimensions;
  selectedAssetIndex = 0;
  slidesPerView = 3;
  isBrowser: boolean;
  private gallery: any;
  private mobileSwiperInstance: Swiper;
  private desktopSwiperInstance: Swiper;
  isMobile: boolean | null = null;
  vimeoWidth = 540;
  vimeoHeight = 540;
  displayedAssets: AssetWithDimensions[] = [];

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private stateService: StateService,
    private changeDetector: ChangeDetectorRef,
    private logger: NGXLogger) {
      this.isBrowser = isPlatformBrowser(this.platformId);
    }

    async ngOnInit() {
      this.selectImage(this.selectedAssetId);
      await this.stateService.select(state => state.isMobile).pipe(take(1)).subscribe(isMobile => {
        this.isMobile = isMobile;
      });
    }

  ngOnChanges() {
    this.logger.debug(`[ngOnChanges]assets`, this.assets, this.selectedAssetId);
    if (this.assets) {
      this.initPhotoswipe();
      // Wait for the swipers to update before sliding to the selected asset
      if (!this.isMobile && this.desktopSwiperInstance) {
        this.desktopSwiperInstance.update();
        this.changeDetector.detectChanges();
        setTimeout(() => {
          this.selectImage(this.selectedAssetId);
          this.desktopSwiperInstance.slideTo(this.selectedAssetIndex, 0);
        }, 100);
      }
      if (this.isMobile && this.mobileSwiperInstance) {
        this.mobileSwiperInstance.update();
        this.changeDetector.detectChanges();
        setTimeout(() => {
          this.selectImage(this.selectedAssetId);
          this.mobileSwiperInstance.slideTo(this.selectedAssetIndex, 0);
        }, 100);
      }
    }
  }

  ngAfterViewInit() {
    if (this.assets) {
      this.initPhotoswipe();
      if (this.isBrowser) {
        if (this.isMobile) {
          this.mobileSwiperInstance =  new Swiper(this.mobileSwiper.nativeElement);//, {
        } else {
          this.desktopSwiperInstance = this.swiper.nativeElement.swiper;
        }
        this.slideToSelectedAsset();
      }
      setTimeout(() => {
        this.getVimeoSize();
      });
    }
  }

  @HostListener('window:resize', [])
  onResize() {
    this.getVimeoSize();
  }

  onLoad() {
    this.featuredAssetLoaded = true;
  }

  swipeToNext() {
    if (!this.swiper.nativeElement.swiper.isEnd) {
      this.swiper.nativeElement.swiper.slideNext();
    }
  }

  swipeToPrev() {
    if (!this.swiper.nativeElement.swiper.isBeginning) {
      this.swiper.nativeElement.swiper.slidePrev();
    }
  }

  private initPhotoswipe() {
    if (this.isBrowser) {
      const items = this.assets?.map(asset => ({
        src: asset.preview,
        msrc: `${asset.preview}?preset=medium`,
        width: asset.width || 1000,
        height: asset.height || 1000,
      }));
      this.gallery = new PhotoSwipeLightbox({
        dataSource: items,
        pswpModule: () => import('photoswipe'),
        showHideOpacity: true,
      });
      this.gallery.init();
    }
  }

  previousImage() {
    if (this.assets && this.assets.length > 0) {
      this.selectedAssetIndex = (this.selectedAssetIndex - 1 + this.assets.length) % this.assets.length;
      this.selectedAsset = this.assets[this.selectedAssetIndex];
    }
  }

  nextImage() {
    if (this.assets && this.assets.length > 0) {
      this.selectedAssetIndex = (this.selectedAssetIndex + 1) % this.assets.length;
      this.selectedAsset = this.assets[this.selectedAssetIndex];
    }
  }

  selectImage(assetId: string) {
    if (assetId !== null && this.assets) {
      const index = this.assets.findIndex(a => a.id === assetId);
      if (index !== -1) {
        this.selectedAssetIndex = index;
        this.selectedAsset = this.assets[this.selectedAssetIndex];
      }
    } else if (this.assets) {
      this.selectedAssetIndex = 0;
      this.selectedAsset = this.assets[0];
    }
    this.slideToSelectedAsset();
  }

  openImage(assetId: string) {
    if (!this.assets) {
      return;
    }
    const index = this.assets.findIndex(a => a.id === assetId);
    if (this.assets[index].type === 'IMAGE') {
      this.gallery.loadAndOpen(index);
    }
  }

  private slideToSelectedAsset() {
    if (this.isMobile && this.mobileSwiperInstance) {
      this.mobileSwiperInstance.slideTo(this.selectedAssetIndex, 0);
    }
    if (!this.isMobile && this.desktopSwiperInstance) {
      this.desktopSwiperInstance.slideTo(this.selectedAssetIndex, 0);
    }
    this.changeDetector.detectChanges();
  }

  getVimeoSize() {
    if(!this.isBrowser) {
        return;
    }
    if (this.isMobile) {
        const rect = this.mobileSwiper.nativeElement.getBoundingClientRect();
        this.vimeoWidth = rect.width;
        this.vimeoHeight = rect.width;
    } else {
        const rect = this.swiper.nativeElement.getBoundingClientRect();
        this.vimeoWidth = rect.width;
        this.vimeoHeight = rect.width;
    }
  }
}
