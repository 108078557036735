import { NgxLoggerLevel } from "ngx-logger";
export const environment = {
    name: 'development',
    production: false,
    hideAccount: true,
    loggerLevel: NgxLoggerLevel.DEBUG,
    shopHost: 'https://dev.dealomega.com',
    apiHost: 'https://api.dealomega.net',
    apiPort: 443,
    shopApiPath: 'shop-api',
    baseHref: '/',
    tokenMethod: 'cookie',
    stripePublishableKey: 'pk_test_51OwmXMP61fl65SW8jTSzD4yERTOPdKY1Fh3QDkOWgB7gqTspdMqeMLAZ6sdiZ0IUgmANVlA7TuuNhIHWynGrhx0r00ylW4C2cI',
    channelToken: '',
    gaTrackingId: 'G-7G2L29ZWRD',
    reCAPTCHA_siteKey: '6LdDdwEqAAAAAHY5DjMwS7hk6u2mQjDxn1YcC3Bb',
    clarityTrackingId: 'nlyins8c2a',
    omniSendAccountId: '66b7e3de6cd3a4a929491cbb',
    version: '0.3.1-1113-dev'
};
