<div *ngIf="isMobile">
    <hr>
    <div class="flex justify-between items-center ml-4 py-2 height-[56px]">
      <div>
        <ng-container *ngIf="(selectedVariantMSRP && discountPercentage); else normalPrice">
          <div class="flex flex-row items-center">
            <div>
              <span class="save-label whitespace-nowrap mr-2">
                -{{ discountPercentage | number:'1.0-0' }}%
              </span>
            </div>
            <div>
              <p class="font-[#2E3742]">
                <span class="currency-tag">{{ (selectedVariant?.price | separatedFormatPrice)?.currency }}</span>
                <span class="price-number">{{ (selectedVariant?.price | separatedFormatPrice)?.price }}</span>
              </p>
            </div>
          </div>
        </ng-container>
        <ng-template #normalPrice>
          <p class="font-[#2E3742]">
            <span class="currency-tag">{{ (selectedVariant?.price | separatedFormatPrice)?.currency }}</span>
            <span class="price-number">{{ (selectedVariant?.price | separatedFormatPrice)?.price }}</span>
          </p>
        </ng-template>
      </div>
      <div class="mr-2">
        <button type="submit" class="mobile-button max-w-xs flex-1 border border-transparent rounded-md py-2 px-6 flex items-center justify-center w-full whitespace-nowrap"
            [ngClass]="{'bg-gray-400': (inFlight || !inStock)}"
            [ngStyle]="(!inFlight && inStock) ? {'background-color': buttonBgColor, 'color': buttonFtColor} : {'color': buttonFtColor}"
            [disabled]="inFlight || !inStock"
            (click)="addToCartAndOpenDrawer()"
        >
          <div>{{inStock?'Shop it Now':'Sold Out'}}</div>
        </button>
      </div>
    </div>
</div>
<div *ngIf="(isMobile===false)">
    <div class="flex align-baseline mt-8">
        <button
            type="submit"
            class="button-text w-100 flex-1 border border-transparent rounded-md py-3 px-8 flex items-center justify-center
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:ring-primary-500"
            [ngClass]="{
                'bg-gray-400': (inFlight || !inStock),
                'hover:bg-primary-700': !inFlight && inStock
            }"
            [ngStyle]="(!inFlight && inStock) ? {'background-color': buttonBgColor, 'color': buttonFtColor} : {'color': buttonFtColor}"
            [disabled]="inFlight || !inStock"
            (click)="addToCartAndOpenDrawer()"
        >
            <div>{{inStock?'Shop it Now':'Sold Out'}}</div>
        </button>
    </div>
</div>
