import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { combineLatest, Observable, Subject } from 'rxjs';

import { GuestOrderStatusQuery, GuestOrderStatusQueryVariables } from '../../../common/generated-types';
import { DataService } from '../../providers/data/data.service';
import { GET_GUEST_ORDER_STATUS } from './order-status-form.graphql';

import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map, takeUntil } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NGXLogger } from 'ngx-logger';
import { StateService } from '../../providers/state/state.service';
import { environment } from '../../../../environments/environment';
import { DEFAULT_HIDE_ACCOUNT_VALUE } from '../../../common/constants';
import { CanonicalService } from '../../providers/canonical/canonical.service';



@Component({
    selector: 'vsf-order-status-form',
    templateUrl: './order-status-form.component.html',
    styleUrls: ['./order-status-form.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderStatusFormComponent implements OnInit, OnDestroy {
  orderForm = new FormGroup({
    orderCode: new FormControl('', Validators.required),
    emailAddress: new FormControl('', [Validators.required, Validators.email])
  });

  order$: Observable<GuestOrderStatusQuery['guestOrderStatus']>;
  errorMessage = '';
  baseUrl = '';
  shopHost = '';
  hideAccount = DEFAULT_HIDE_ACCOUNT_VALUE;

  private destroy$: Subject<void> = new Subject<void>();
  constructor(private dataService: DataService,
              private stateService: StateService,
              private canonicalService: CanonicalService,
              private router: Router,
              @Inject(PLATFORM_ID) private platformId: object,
              private logger: NGXLogger) {}

  ngOnInit() {
    this.hideAccount = typeof environment.hideAccount === 'boolean' ? environment.hideAccount : DEFAULT_HIDE_ACCOUNT_VALUE;
    const storePath$ = this.stateService.select(state => state.storePath).pipe(takeUntil(this.destroy$));
    const shopHost$ = this.stateService.select(state => state.shopHost).pipe(takeUntil(this.destroy$));
    combineLatest([storePath$, shopHost$]).subscribe(([storePath, shopHost]) => {
      this.baseUrl = storePath;
      this.shopHost = shopHost;
      const canonicalURL = `${this.baseUrl}/orderstatus`;
      this.canonicalService.setCanonicalURL(`${this.shopHost}${canonicalURL}`);
    });
  }

  ngOnDestroy() {
    this.canonicalService.removeCanonicalURL();
    this.destroy$.next();
    this.destroy$.complete();
  }

  navigateToSignIn() {
    this.router.navigate([`${this.baseUrl}/account`, 'sign-in']);
  }

  onSubmit() {
    this.errorMessage = '';
    if(this.orderForm.valid) {
      try{
        const formValue = this.orderForm.value;
        this.order$ = this.dataService.query<GuestOrderStatusQuery, GuestOrderStatusQueryVariables>(
          GET_GUEST_ORDER_STATUS,
          {
            orderCode: formValue.orderCode??'',
            email: formValue.emailAddress??''
          },
          'network-only'
        ).pipe(
          map(data => data.guestOrderStatus),
          takeUntil(this.destroy$)
        );
        this.order$.subscribe(data => {
           if(!data) {
             this.errorMessage = 'No order found with the provided details';
           }
        });
      } catch(error) {
        this.errorMessage = 'An error occurred while fetching order status';
        this.logger.error(error);
      }
    } else {
      this.errorMessage = 'Please enter a valid order code and email address';
    }
  }
}
